import React from 'react'
import { Col, Nav } from 'react-bootstrap';
import { FiShare2 } from "react-icons/fi";
import { GiShoppingCart } from "react-icons/gi"
import { MdStorefront } from "react-icons/md"
import {ProSidebar, Menu, MenuItem, SidebarContent} from "react-pro-sidebar";
import { Link } from 'react-router-dom';

const MenuSidebar = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
    return (
        <div>
        <Col as="div">
            <Nav className="d-block pt-5 bgwhite">
                <span className="d-block ps-4 pb-4 bottomshadow">
                    <img src="/assets/menuside-logo.png" className="img-fluid" alt="navimg" />
                </span>
            </Nav>
            <ProSidebar
                className="SidebarNav accountNav w-auto"
                image={false}
                rtl={rtl}
                collapsed={collapsed}
                toggled={toggled}
                breakPoint="md"
                onToggle={handleToggleSidebar}>
                <SidebarContent className='pb-2'>
                    <Menu className="singleSubMenu">
                        <MenuItem>
                            <Link to="/hoststepone" className='d-inline-flex align-items-center fs20 semibold GrayComn'>
                                <Col as="span" className='icon-fixed d-inline-block me-2'><GiShoppingCart size="35" className="themeColor align-text-bottom" /></Col>
                                Order Dashboard
                            </Link>
                        </MenuItem>                        
                    </Menu>
                    <Menu iconShape="circle" className="singleSubMenu">
                        <MenuItem className="semibold">
                        <Link to="/hostcribone" className='d-inline-flex align-items-center fs20 semibold GrayComn'>
                            <Col as="span" className='icon-fixed d-inline-block me-2'><MdStorefront size="30" className="themeColor align-text-bottom me-2"/></Col>
                            My Crib
                        </Link>
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle" className="singleSubMenu">
                        <MenuItem className="semibold">
                        <Link to="/hostmenuone" className='d-inline-flex align-items-center fs20 semibold GrayComn'>
                            <Col as="span" className='icon-fixed d-inline-block me-2'><MdStorefront size="30" className="themeColor align-text-bottom me-2"/></Col>
                            My Menu
                        </Link>
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle" className="singleSubMenu">
                        <MenuItem className="semibold">
                        <Link to="/hostmarketingone" className='d-inline-flex align-items-center fs20 semibold GrayComn'>
                            <Col as="span" className='icon-fixed d-inline-block me-2'>
                            <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.07487 14.1102H6.97084C7.37033 14.1102 7.69395 13.7863 7.69395 13.3871V5.7653C7.69395 5.36585 7.37025 5.04219 6.97084 5.04219H3.07487C2.67538 5.04219 2.35176 5.36613 2.35176 5.7653V13.3871C2.35176 13.7866 2.67546 14.1102 3.07487 14.1102ZM6.24777 6.48846V12.6641H3.79799V6.48846H6.24777Z" fill="#C32148" stroke="#C32148" stroke-width="0.4"/>
                            <path d="M1.6063 12.9813H2.87462V12.9815H3.07462C3.47411 12.9815 3.79773 12.6576 3.79773 12.2584V6.8903C3.79773 6.49085 3.47403 6.16719 3.07462 6.16719H1.6063C0.859479 6.16719 0.252148 6.77434 0.252148 7.52134V11.6273C0.252148 12.374 0.859485 12.9813 1.6063 12.9813ZM2.35155 7.6133V11.5351H1.69834V7.61319L2.35155 7.6133Z" fill="#C32148" stroke="#C32148" stroke-width="0.4"/>
                            <path d="M21.459 18.6751H21.4882L21.4908 18.6743C21.5483 18.6717 21.6058 18.6622 21.6621 18.6457L21.6622 18.6457C21.9701 18.5558 22.1823 18.2733 22.1823 17.9521V1.20369C22.1823 0.882021 21.9702 0.599144 21.6613 0.509406L21.6055 0.701463L21.6628 0.509843C21.3506 0.416518 21.0205 0.545264 20.8489 0.815697L20.8487 0.816023C20.8488 0.815776 20.8379 0.832735 20.8155 0.865321C20.7935 0.897381 20.7609 0.943851 20.7178 1.00266C20.6317 1.1203 20.5036 1.28716 20.3344 1.48674C19.9959 1.8861 19.4938 2.41525 18.8346 2.94298C17.5159 3.99861 15.5759 5.04307 13.0593 5.04307H6.9694C6.56991 5.04307 6.24629 5.367 6.24629 5.76618V13.3895C6.24629 13.7889 6.56999 14.1126 6.9694 14.1126L13.0593 14.1128C15.5666 14.1128 17.5066 15.1573 18.8278 16.2133C19.4883 16.7412 19.9921 17.2705 20.3322 17.6701C20.5022 17.8697 20.6309 18.0366 20.7177 18.1543C20.761 18.2132 20.7939 18.2597 20.8161 18.2918C20.8202 18.2978 20.8239 18.3032 20.8273 18.3082C20.833 18.3166 20.8377 18.3235 20.8413 18.3289L20.8479 18.3388L20.8497 18.3414L20.8497 18.3415L20.8502 18.3422C20.9855 18.5534 21.2173 18.6751 21.459 18.6751ZM13.059 12.6665H7.69224L7.69242 6.48939L13.0592 6.48957C16.7382 6.48957 19.3102 4.61932 20.736 3.19415L20.7358 15.962C19.3099 14.5369 16.738 12.6665 13.059 12.6665Z" fill="#C32148" stroke="#C32148" stroke-width="0.4"/>
                            <path d="M21.1414 7.38913C21.6296 7.01707 22.2062 6.82031 22.809 6.82031V12.3322C22.2016 12.3322 21.6248 12.1374 21.1414 11.7687C21.0116 11.6697 20.9355 11.5158 20.9355 11.3526V7.80522C20.9355 7.64204 21.0116 7.48817 21.1414 7.38913ZM21.1414 7.38913L21.1414 7.38913L21.1414 7.38913ZM21.0201 11.9277C21.485 12.2823 22.0313 12.4872 22.609 12.5256V12.5322H22.809C24.4365 12.5322 25.7597 11.2074 25.7597 9.57902C25.7597 7.94799 24.4368 6.62052 22.809 6.62031H22.809C22.1615 6.62031 21.5425 6.83199 21.0202 7.23006L21.0201 7.23014C20.8407 7.36703 20.7355 7.57971 20.7355 7.80522V11.3526C20.7355 11.5781 20.8407 11.7908 21.0201 11.9277L21.0201 11.9277ZM22.809 11.0861C22.5886 11.0861 22.3767 11.0394 22.1817 10.9494V8.20725C22.379 8.11452 22.5913 8.06669 22.809 8.06669C23.6376 8.06669 24.3135 8.74435 24.3135 9.57902C24.3135 10.4103 23.6382 11.0861 22.809 11.0861Z" fill="#C32148" stroke="#C32148" stroke-width="0.4"/>
                            <path d="M6.72629 21.3257H7.21103C7.21104 21.3257 7.21104 21.3257 7.21105 21.3257C8.25728 21.3257 9.10802 20.4749 9.10802 19.4287V13.3864C9.10802 12.9869 8.78431 12.6633 8.3849 12.6633H5.55241C5.15296 12.6633 4.8293 12.987 4.8293 13.3864V19.4287C4.8293 20.4749 5.68005 21.3257 6.72629 21.3257ZM6.2755 19.4286V14.1094H7.66179V19.4286C7.66179 19.6769 7.45935 19.8793 7.21103 19.8793H6.72626C6.47795 19.8793 6.2755 19.6769 6.2755 19.4286Z" fill="#C32148" stroke="#C32148" stroke-width="0.4"/>
                            </svg>
                            </Col>
                            Marketing
                            </Link>
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle" className="singleSubMenu">
                        <MenuItem className="semibold">
                            <FiShare2 className="me-4 align-text-bottom" size="24" color="var(--theme-color)" />
                            <span className="pt-2 d-inline-block">Business Insights</span>
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle" className="singleSubMenu">
                        <MenuItem className="semibold">
                            <FiShare2 className="me-4 align-text-bottom" size="24" color="var(--theme-color)" />
                            <span className="pt-2 d-inline-block">Reviews</span>
                        </MenuItem>
                    </Menu>
                </SidebarContent>                    
            </ProSidebar>  
        </Col>
        </div>
    )
}

export default MenuSidebar;