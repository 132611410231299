import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FiChevronDown, FiLogIn, FiMapPin, FiMenu } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import { BiCalendar, BiSearch } from "react-icons/bi";
import { Form, Button, Dropdown, Image } from 'react-bootstrap';
import { List } from 'react-bootstrap-icons';
import { HiOutlineUser } from 'react-icons/hi';

const BeforeLoginHeader = () => {
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <header className="w-100 d-flex align-items-center justify-content-between">
      {/* logo */}
      <div className="rightSection">
        <Link to="/" className="mt-3 mb-md-0 logo">
          <img src="./logo.png" alt="logo" />
        </Link>
      </div>
      {/* navigation */}
      <div className="menu-start">
        <nav className="navbar navmenu navbar-expand-lg py-0 float-md-start">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <List className="sarchIcon" size={22} />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li>
                  <Link to="" className="nav-link active">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="" className="nav-link">
                    Company
                  </Link>
                </li>
                <li>
                  <Link to="/findmeal_one" className="nav-link">
                    Diner
                  </Link>
                </li>
                <li>
                  <Link to="/hostsignup" className="nav-link">
                    Host
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* user account */}
      <div className="my-account">
        <div className="downBox position-relative">
          <Link className="loginBtn btn rounded-pill" to="/loginpage">
            <FiLogIn /> <span className="Hide-xs">Login</span>
          </Link>
          <Link
            className="signupBtn btn rounded-pill"
            to="/dinersignup"
          >
            <HiOutlineUser size={20} />{" "}
            <span className="Hide-xs">Signup</span>
          </Link>
        </div>
      </div>
    </header>
  )
}

export default BeforeLoginHeader;