import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap';
import { addClass } from '../../commanFunctions';

const DinerSignupThree = params => {
    const [errorMessage, setErrorMessage] = useState(undefined);
    const checkUser = gql`
    query checkUserExist($email:String!){
        checkUserExist(email:$email)
        {
          status
          message
        }
      }
  `;
    const { data, error } = useQuery(checkUser, { variables: { email: params.email } });
    const onNextPage = async (event) => {
        if (params.email !== '') {
            if (data.checkUserExist.status == "success") {
                addClass()
                setTimeout(() => {
                    params.setStepNo(4)
                }, 1400)
            }
            else {
                setErrorMessage({
                    error: 'empty email',
                    message: data.checkUserExist.message
                })
            }
        }
        else {
            setErrorMessage({
                error: 'empty email',
                message: 'Please Enter Email'
            })
        }
    };

    const goBack = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(2)
        }, 1400)
    }

    useEffect(() => {
        if (error) {
            console.log('error', error);
            if (error.message === 'Failed to fetch') {
                setErrorMessage({
                    error: 'empty email',
                    message: error.message
                })
            } else {
                const errMessage = error.message ? JSON.parse(error.message) : ''
                setErrorMessage({
                    error: 'empty email',
                    message: errMessage
                })
            }
        }
    }, [error]);

    return (
        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front overflow-visible">
                <div className="heading">
                    <h2 className='small lh-1 fs-normal mb-2'>Signup</h2>
                    <p className="semibold m-0">Please input your email address.</p>
                </div>
                <Col as="section" className="stepsBar pt-4">
                    <Col as="ul" className="d-flex justify-content-between list-unstyled">
                        <li className="p2 active first"><span>1</span></li>
                        <li className="p2 active"><span>2</span></li>
                        <li className="p2 active"><span>3</span></li>
                        <li className="p2" ><span>4</span></li>
                        <li className="p2 last"><span>5</span></li>
                    </Col>
                </Col>
                <Col as="section" className="signpUser pt-3">
                    <Form>
                        <Form.Group className="mb-4 pb-2" controlId="formEmail">
                            <Form.Control type="email" placeholder="Email Address"
                                value={params.email}
                                onChange={(e) => {
                                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
                                        setErrorMessage({
                                            error: 'empty email',
                                            message: 'Invalid Email '
                                        })
                                    } else {
                                        setErrorMessage(undefined)
                                    }
                                    params.setEmail(e.target.value);
                                }} />
                            {errorMessage && errorMessage.error === 'empty email' && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                        {/* <Button onClick={onFifthpage} className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary" >Continue</Button> */}
                        <Row>
                            <Col className="align-self-center">
                                <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button" onClick={goBack}>
                                    <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Previous
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                                    variant="primary"
                                    type="button"
                                    onClick={onNextPage}>
                                    Next
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </div>
        </section>
    )
}
export default DinerSignupThree