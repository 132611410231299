import React, { useState } from 'react'
import { Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { addClass } from '../../commanFunctions';

const DinerSignupFour = params => {
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [otpone, setOtpOne] = useState('');
    const [otptwo, setOtpTwo] = useState('');
    const [otpthree, setOtpThree] = useState('');
    const [otpfour, setOtpFour] = useState('');
    const [otpfive, setOtpFive] = useState('');
    // const [otpsix, setOtpSix] = useState('');

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }
    }

    const onAutoSubmit = async (otpsix) => {
        const Otp = otpone + otptwo + otpthree + otpfour + otpfive + otpsix;
        if (Otp == "123456") {
            addClass()
            setTimeout(() => {
                params.setStepNo(5)
            }, 1400)

        }
        else {
            setErrorMessage({
                error: 'invalid otp',
                message: 'Invalid OTP'
            })
        }
    };

    const backOnEmail = () => {
        addClass()
        setTimeout(()=> {
            params.setStepNo(3)
        },1400)
    }

    return (
        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front overflow-visible">
                <div className="heading">
                    <h2 className='small lh-1 fs-normal mb-2'>Signup</h2>
                    <p className="semibold m-0">Please key in the OTP sent to your email.</p>
                </div>
                <Col as="section" className="stepsBar pt-4">
                    <Col as="ul" className="d-flex justify-content-between list-unstyled">
                        <li className="p2 active first"><span>1</span></li>
                        <li className="p2 active"><span>2</span></li>
                        <li className="p2 active"><span>3</span></li>
                        <li className="p2" ><span>4</span></li>
                        <li className="p2 last"><span>5</span></li>
                    </Col>
                </Col>
                <Col as="section" className="signpUser pt-3">
                    <Form>
                        <Form.Group controlId="formEmailOtp" className="mb-1 pb-1 row otpScreem">
                            <Col md={2}><input type="text" inputMode="numeric" id="input1" placeholder="." onChange={(e) => {
                                setOtpOne(e.target.value)
                            }} maxLength="1" tabIndex="1" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input2" placeholder="." onChange={(e) => {
                                setOtpTwo(e.target.value)
                            }} maxLength="1" tabIndex="2" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input3" placeholder="." onChange={(e) => {
                                setOtpThree(e.target.value)
                            }} maxLength="1" tabIndex="3" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input4" placeholder="." onChange={(e) => {
                                setOtpFour(e.target.value)
                            }} maxLength="1" tabIndex="4" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" inputMode="numeric" id="input5" placeholder="." onChange={(e) => {
                                setOtpFive(e.target.value)
                            }} maxLength="1" tabIndex="5" onKeyUp={e => inputfocus(e)} /></Col>

                            <Col md={2}><input type="text" id="input6" placeholder="." onChange={(e) => {
                                // setOtpSix(e.target.value)
                                onAutoSubmit(e.target.value)
                            }} maxLength="1" tabIndex="6" inputMode="numeric" onKeyUp={e => inputfocus(e)} /></Col>
                            {errorMessage && errorMessage.error === 'invalid otp' && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4 pb-4" controlId="exampleForm.ControlTextarea1">
                            <Form.Label className='float-start'>
                                <Link to="#" className="forgotPass text-decoration-none">Resend</Link>
                                <span className="text-black px-1">or</span>
                                <Link to="#" className="forgotPass text-decoration-none" onClick={backOnEmail}>Change Email</Link>
                            </Form.Label>
                            <Form.Label className='float-end mt-1'>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.1473 20.0918C12.8177 20.0918 15.3195 19.0525 17.231 17.1705C19.1423 15.2885 20.1543 12.7603 20.1543 10.0918C20.1543 7.42333 19.1143 4.92321 17.231 3.01307C15.3476 1.10304 12.8176 0.0917969 10.1473 0.0917969C7.47692 0.0917969 4.97504 1.13108 3.06356 3.01307C-0.815458 6.91761 -0.815458 13.266 3.06356 17.1705C4.97514 19.0526 7.47692 20.0918 10.1473 20.0918ZM3.87875 3.82759C7.33622 0.372347 12.9583 0.372347 16.4158 3.82759C18.1025 5.5131 19.0019 7.73212 19.0019 10.0917C19.0019 12.4513 18.0743 14.6985 16.4158 16.3558C12.9583 19.811 7.33627 19.811 3.87875 16.3558C0.421077 12.9008 0.421077 7.28268 3.87875 3.82759Z" fill="#404040" />
                                    <path d="M9.65421 12.0917H13.6543C13.9293 12.0917 14.1543 11.8293 14.1543 11.5085C14.1543 11.1876 13.9293 10.9252 13.6543 10.9252L10.1542 10.925V5.67507C10.1542 5.35423 9.92925 5.0918 9.65426 5.0918C9.37924 5.0918 9.1543 5.35425 9.1543 5.67507V11.5083C9.1543 11.8292 9.37926 12.0918 9.65426 12.0918L9.65421 12.0917Z" fill="#404040" />
                                </svg> <b>00.25</b></Form.Label>
                        </Form.Group>
                        {/* <Button type='button' className="w-100 py-2 lh-lg border-0 hvr-float-shadow1" variant="primary" onClick={onNextPage} >Continue</Button> */}
                        <br /><br />
                    </Form>
                </Col>
            </div>
        </section>
    )
}
export default DinerSignupFour