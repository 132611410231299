import { gql } from "@apollo/client";
export const queries = (id) => {
  return gql`
    query outletDetails {
      outletDetails(id: ${id}) {
        status
        message
        data {
          name
          id
          address
          cuisines {
            title
            id
          }
          vendor {
            categories {
              category {
                title
              }
            }
          }
        }
      }
    }
  `;
};

export const getMenuItems = (id) => {
  return gql`
    query menuDetails {
      menuDetails(cuisine_id: 1) {
        status
        message
        data {
          id
          title
        }
      }
    }
  `;
};

export const checkUserExist = (email) => {
  return gql`
  query checkUserExist($email:String!){
      checkUserExist(email:$email)
      {
        status
        message
      }
    }
`;
};

export const hostSignup = () => {
  return gql`
  mutation registeredHost(
    $nricfront: Upload
    $nricback: Upload
    $acradoc: Upload
    $registration_type: String!
    $dob: DateTime!
    $nric_no: String!
    $age: String!
    $gender: String!
    $citizenship: String!
    $address: String!
    $user_role: Float!
    $company_name: String!
    $registration_no: String!
    $date_inc: DateTime!
    $company_status: String!
    $primary_business: String!
    $sec_business: String!
    $share_capital: String!
    $designation: String!
    $currency: String!
    $user_host_type: String!
    $rep_citizenship: String!
    $outlets: [OutletRegistrationInput!]!
    $representative: String!
    $rep_nric_no: String!
    $company_amendement: Boolean!
    $profile_amendment: Boolean!
    $company_address: String!
  ) {
    registeredHost(
      registerdData: {
        registration_type: $registration_type
        dob: $dob
        nric_no: $nric_no
        age: $age
        gender: $gender
        citizenship: $citizenship
        address: $address
        user_role: $user_role
        nricfront: $nricfront
        nricback: $nricback
        acradoc: $acradoc
        company_name: $company_name
        registration_no: $registration_no
        date_inc: $date_inc
        company_status: $company_status
        primary_business: $primary_business
        sec_business: $sec_business
        share_capital: $share_capital
        designation: $designation
        currency: $currency
        user_host_type: $user_host_type
        outlets: $outlets
        representative_citizenship: $rep_citizenship
        representative:$representative
        rep_nric_no: $rep_nric_no
        amendement: $company_amendement
        profile_amendment: $profile_amendment
        company_address: $company_address
      }
    ) {
      status
      message
      user {
        id
        email
      }
    }
  }    
  `;
};

export const dinerSignup = () => {
  return (
    gql`
    mutation AddUser($firstName: String!,
        $lastName: String!,
        $email: String!,$phoneNumber: String!,
        $jobTitle: String!,$department: String!,
        $password: String!, $empolyeeNumber: String!,
        $userRole: Float!) {
        addUser(user:{
          email:$email,
          phoneNumber:$phoneNumber
          firstName: $firstName
          lastName: $lastName
          jobTitle: $jobTitle
          department: $department
          password: $password
          employeeNumber: $empolyeeNumber
          userRole: $userRole
        }){
          status
          message
          accessToken
          user{
           email
            id
            first_name
            last_name
            job_title
            department
            user_role
            employee_number
            role{
              id
              name
            }
          }
        }
      }
    `
  )
}

export const GetUserById = () => {
  return (
    gql`query GetUserById($userId: Float!){ 
    getUserById(userId:$userId)
    {
      status
      message
      user{
        id
        employee_number
        first_name
        last_name
        email
        job_title
        department
        user_role
        phone_number
        auth_login
        auth_order
        auth_account_changes
        auth_method
        role
        {
          id
          name
        }
        user_profile{
          id
          gender
          dob
          account_verified
          profile_image
          is_allergic
          allergies
          dietary_prefrence
          current_address
          home_address
        }
        emergency_contact{
          id
          first_name
          last_name
        }
      }
    }
  }
   `)

}
export const verifyToken = () => {
  return (gql`
  query verifyToken {
    verifyToken {
      status
      user {
        id
        first_name
        last_name
        is_host
        last_login
        email
        phone_number
      }
      message
    }
  }
`)
}

export const editDiner = () => {
  return (gql`
  mutation editDiner( 
    $id:Float!,
    $firstName: String!, 
    $lastName: String!,
    $email: String!,
    $phoneNumber: String,
    $password: String!, 
    $userRole: String!,
    $dob: DateTime!, 
    $gender: String!, 
    $dietary_prefrence: [String!],
    $allergies: [String!], 
    $profile_image: Upload, 
    $emergency_contact: [EmergencyContactInput!] !,
    $auth_method: String!, 
    $auth_login: Boolean!, 
    $status: String!, 
    $auth_account_changes: Boolean!,
    $auth_order: Boolean!,
  ) 
  { editDiner(
    user: {
      id: $id,
      email: $email, 
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName 
      password: $password
      userRole: $userRole 
      dob: $dob
      gender: $gender 
      dietary_prefrence: $dietary_prefrence, 
      allergies: $allergies
      profile_image: $profile_image
      auth_login: $auth_login 
      auth_account_changes: $auth_account_changes 
      auth_order: $auth_order 
      auth_method: $auth_method 
      status: $status 
      emergency_contact: $emergency_contact 
    })
    { 
      status 
      message
      user {
        email 
        id
        first_name
        last_name
        job_title 
        department
        user_role
        password 
        employee_number
        role { 
          id 
          name
        } 
        status
        user_profile
        {
          gender 
          dob 
          dietary_prefrence 
        } 
      }
    }
  }`)
}