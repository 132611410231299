import { useMutation } from "@apollo/client";
import React, { createRef, useEffect, useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { HiCheckCircle } from "react-icons/hi";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { addClass } from "../../../commanFunctions";
import { hostSignup } from "../../../queries/queries";

const HostBusiness15 = (params) => {
    const history = useNavigate();
    const [termsCondition, setTermsCondition] = useState(false);
    const [permissions, setPermissions] = useState(false);
    const [, setMarketingUpdates] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [errorPopupMessage, setPopupErrorMessage] = useState(undefined);
    const [errorPopup, setErrorPopup] = useState(false);
    const [createAcc, setCreateAcc] = useState(false);
    const [onShow, setOnShow] = useState(true)
    const [recaptchaToken, setRecaptchaToken] = useState('')
    const [render, setRender] = useState(false);

    const reCaptchaRef = createRef({})

    const handleCaptchaToken = (e) => {
        if (!!e === false) {
            setErrorMessage({
                error: "unchecked captcha",
                message: "Please checked captchbox",
            })
        } else {
            setRecaptchaToken(e)
            setErrorMessage(undefined)
        }
    }

    const onCaptchaRender = () => {
        const grecaptcha = reCaptchaRef?.current?.props?.grecaptcha;
        grecaptcha?.render("gcaptcha", { sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY, callback: handleCaptchaToken })
    }

    useEffect(() => {
        setRender(true)
        if (render) {
            onCaptchaRender()
        }
        // eslint-disable-next-line
    }, [render])


    const signUp = hostSignup();
    const [SignUpUser, { data, error }] = useMutation(signUp, {
        variables: {
            nricfront: params.nricfront,
            nricback: params.nricback,
            acradoc: params.acradoc,
            registration_type: params.registrationType,
            dob: params.dob,
            nric_no: params.nricNumber,
            age: params.age,
            gender: params.gender,
            citizenship: params.citizenship,
            address: params.address,
            user_role: 1,
            company_name: params.company_name,
            registration_no: params.registration_no,
            date_inc: params.date_inc,
            company_status: params.company_status,
            primary_business: params.primary_business,
            sec_business: params.sec_business,
            share_capital: params.share_capital,
            designation: params.designation,
            currency: params.currency,
            user_host_type: "business",
            rep_citizenship: params.rep_citizenship,
            outlets: params.outlets,
            representative: params.representative,
            company_address: params.companyAddress,
            rep_nric_no: params.repNricNO,
            company_amendement: params.companyAmendment,
            profile_amendment: params.profileAmendment,
        },
    });

    const onCreateAcc = async (event) => {
        if (permissions === false) {
            setErrorMessage({
                error: "permissions not agree",
                message: "Please Agree to Permissions",
            });
        } else if (termsCondition === false) {
            setErrorMessage({
                error: "terms condition",
                message: "Please Agree to terms & Conditions",
            })
        } else if (!!recaptchaToken === false) {
            setErrorMessage({
                error: "unchecked captcha",
                message: "Please checked captchbox",
            })
        } else {
            setCreateAcc(true);
        }
    };

    useEffect(() => {
        (async () => {
            if (createAcc) {
                try {
                    await SignUpUser({
                        variables: {
                            nricfront: params.nricfront,
                            nricback: params.nricback,
                            acradoc: params.acradoc,
                            registration_type: params.registrationType,
                            dob: params.dob,
                            nric_no: params.nricNumber,
                            age: params.age,
                            gender: params.gender,
                            citizenship: params.citizenship,
                            address: params.address,
                            user_role: params.userRole,
                            company_name: params.company_name,
                            registration_no: params.registration_no,
                            date_inc: params.date_inc,
                            company_status: params.company_status,
                            primary_business: params.primary_business,
                            sec_business: params.sec_business,
                            share_capital: params.share_capital,
                            designation: params.designation,
                            currency: params.currency,
                            user_host_type: params.userHostType,
                            rep_citizenship: params.rep_citizenship,
                            outlets: params.outlets,
                            representative: params.representative,
                            company_address: params.companyAddress,
                            rep_nric_no: params.repNricNO,
                            company_amendement: params.companyAmendment,
                            profile_amendment: params.profileAmendment,
                        },
                    });
                } catch (err) {
                    setErrorPopup(true);
                    setPopupErrorMessage("Something went wrong")
                }
            }
        })();
        // eslint-disable-next-line
    }, [createAcc, params.nricfront]);

    useEffect(() => {
        if (data) {
            if (data.registeredHost.status === "success") {
                history("/");
            } else if (data.registeredHost.status === "error") {
                setErrorPopup(true);
                setPopupErrorMessage(data?.registeredHost?.message)
            } else {
                // console.log(data);
            }
        }
        if (error) {
            if (error.message === "Failed to fetch") {
                setErrorPopup(true);
                setPopupErrorMessage(error?.message)
            } else {
                setErrorPopup(true);
                setPopupErrorMessage("Something went wrong")
            }
        }
        // eslint-disable-next-line
    }, [data, error]);

    const goBack = () => {
        addClass();
        setTimeout(() => {
            params.setStepNo(14);
        }, 1400);
    };

    return (
        <>
            <section id="signUpFlip" className="mobilePur m-auto cardBox  d-flex align-items-center h-100">
                <div className="front">
                    <div className="heading">
                        <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
                        <p className="semibold m-0 lh-1">Please read, confirm and accept the following terms to proceed.</p>
                    </div>
                    <Col as="section" className="stepsBar2 pt-4">
                        <Col as="ul" className="list-unstyled">
                            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(95%)" }}>15</span></Col>
                        </Col>
                    </Col>
                    <p className="semibold mb-3 lh-1">For Host</p>
                    <Form className="pt3">
                        <Form.Group
                            className="pb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <div className="form-check d-inline-block">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="flexRadioDefault"
                                    id="host01"
                                    onChange={(e) => {
                                        setPermissions(e.target.checked);
                                    }}
                                />
                                <label className=" form-check-label semibold ps-3 lh-sm" htmlFor="host01">
                                    <b className="text-danger">*</b>I confirm that I have
                                    obtained all required permissions, where necessary, to
                                    provide Cribbly Hosting Services within the registered
                                    Outlet.
                                </label>
                            </div>
                            {errorMessage && errorMessage.error === "permissions not agree" && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group
                            className="pb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <div className="form-check d-inline-block">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="flexRadioDefault"
                                    id="back01"
                                    onChange={(e) => {
                                        setTermsCondition(e.target.checked);
                                        if (!e.target.checked) {
                                            setErrorMessage({
                                                error: "terms condition",
                                                message: "Please Agree to terms & Conditions",
                                            });
                                        } else {
                                            setErrorMessage(undefined);
                                        }
                                    }}
                                />
                                <label
                                    className="form-check-label semibold ps-3  lh-sm"
                                    htmlFor="back01"
                                >
                                    <b className="text-danger">*</b>I confirm that I have
                                    read, understood and accepted Cribbly User{" "}
                                    <Link
                                        to="/"
                                        className="navlink text-danger text-decoration-none"
                                    >
                                        Terms &#38; Conditions
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                        to="/"
                                        className="navlink text-danger text-decoration-none"
                                    >
                                        Privacy Policy
                                    </Link>
                                    .
                                </label>
                            </div>
                            {errorMessage && errorMessage.error === "terms condition" && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group
                            className="mb-4 pb-2"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <div className="form-check d-inline-block">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="flexRadioDefault"
                                    id="back02"
                                    onChange={(e) => {
                                        setMarketingUpdates(e.target.checked);
                                    }}
                                />
                                <label
                                    className="form-check-label semibold ps-3  lh-sm"
                                    htmlFor="back02"
                                >
                                    I would like to receive updates, special offers and
                                    marketing promotions from Cribbly Pte Ltd. This option may
                                    be changed in your account later.
                                </label>
                            </div>
                        </Form.Group>
                        <Form.Group
                            className="mb-2 pb-4 text-center"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            {/* <img src="./captcha.png" alt="captchaImage" /> */}
                            <ReCAPTCHA
                                id="gcaptcha"
                                className="d-inline-block"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={handleCaptchaToken}
                                ref={reCaptchaRef}
                                onExpired={() => setRecaptchaToken(null)}
                            />
                            {errorMessage && errorMessage.error === "unchecked captcha" && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                        <Row>
                            <Col className="align-self-center">
                                <Button
                                    className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                                    variant="link"
                                    type="button"
                                    onClick={goBack}
                                >
                                    <svg
                                        width="9"
                                        height="16"
                                        className="align-baseline me-3"
                                        viewBox="0 0 9 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8 15L1 8L8 1"
                                            stroke="#C32148"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    Previous
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    onClick={onCreateAcc}
                                    className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                                    variant="primary">
                                    Complete
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </section>
            {createAcc === true && errorPopup === true ? (
                <Modal dialogClassName="modalSM" centered show={onShow} onHide={() => setOnShow(false)} >
                    <Modal.Body className="d-flex flex-column justify-content-center align-items-center border08 text-center py-5 bgtheme">

                        <svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                        </svg>

                        <h1 className="text-white fs32 semibold">Error !</h1>
                        <p className="fs24 regular lh-sm mb-5 text-white">
                            {errorPopupMessage}
                        </p>
                    </Modal.Body>
                </Modal>
            ) : createAcc === true && errorPopup === false ? (
                <Modal
                    backdropClassName="action01"
                    dialogClassName="modalSM"
                    contentClassName="action03"
                    centered
                    show={onShow}
                    onHide={() => setOnShow(false)}>
                    <Modal.Body className="d-flex flex-column justify-content-center align-items-center border08 text-center py-5 bgtheme">
                        <HiCheckCircle size="120" className='mt-5 mb-3' color="var(--bs-white)" />
                        <h1 className="text-white fs32 regular">Success!</h1>
                        <p className="fs24 regular lh-sm mb-5 text-white">
                            Your account has been successfully created, Please login to
                            Proceed.
                        </p>
                    </Modal.Body>
                </Modal>
            ) : null}
        </>
    )
}

export default HostBusiness15