import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, FloatingLabel, Modal } from 'react-bootstrap';
import ReactFlagsSelect from "react-flags-select";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { HiCheckCircle, HiHome } from "react-icons/hi";
import { Link, useNavigate } from 'react-router-dom';
import './loginpage.css';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { verifyToken } from '../../queries/queries';


const addClass = () => {
    document.getElementById("signUpFlip").classList.add("scalIn");
    setTimeout(function () {
        document.getElementById("scalOut").classList.add("scalOut");
    }, 100);

    setTimeout(function () {
        document.getElementById("signUpFlip").classList.add("flip");
    }, 1000);

    setTimeout(function () {
        document.getElementById("scalOut").classList.remove("scalOut");
    }, 1000);

    setTimeout(function () {
        document.getElementById("signUpFlip").classList.remove("scalIn");
    }, 1000);
}

const LoginPage = () => {
    const [selected, setSelected] = useState("");
    const history = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordType, setPasswordType] = useState(true)
    const [errorMessage, setErrorMessage] = useState(undefined);
    const SOCAIL_LOGIN_FACEBOOK_KEY = 'facebook';
    const SOCAIL_LOGIN_GOOGLE_KEY = 'google';

    const [onShow, setOnShow] = useState(false)
    const login = gql`
    mutation LoginHost($email: String!, $password: String!){
        loginHost(loginData:{
          email:$email,
          password:$password
        }){
          status
          message
          accessToken
          user{
           email
            id
            first_name
            last_name
            job_title
            department
            user_role
            employee_number
            role{
              id
              name
            }
          }
        }
      }
        `;
    const socailLogin = gql`
    mutation loginWithSocialId(
        $socailLoginProvider:String!,
        $socialLoginId:String!,
        $email:String!,
        $data:JSON){
        loginWithSocialId(user: {
            social_login_provider: $socailLoginProvider,
            social_login_id: $socialLoginId,
            email: $email,
            data: $data,
        }) {
        status
        message
        accessToken
        user{
            id
            first_name
            last_name
            email
            social_login_id
            social_login_provider
        }
        }
    }
    `;

    const [loginUserr, { data, error }] = useMutation(login, { variables: { email: email, password: password } });
    const [socailloginUser] = useMutation(socailLogin);

    const responseGoogle = (res) => {
        if(!res.error){
            sendRequest(res,SOCAIL_LOGIN_GOOGLE_KEY);
        }
        
    }
    const responseFacebook = (res) => {
        if(!res.error){
            sendRequest(res,SOCAIL_LOGIN_FACEBOOK_KEY);
        }
    }
    const sendRequest = async(res,type) => {
    
            if(type == SOCAIL_LOGIN_FACEBOOK_KEY){
                var SocailId = (res.id ? res?.id : res.userID);
                var socailProvider = type;
                var email = res?.email;
                var allData = res;
            }
            if(type == SOCAIL_LOGIN_GOOGLE_KEY){
                var SocailId = res?.googleId;
                var socailProvider = type;
                var email = res?.profileObj?.email;
                var allData = res?.profileObj;
            }
            if(res){
                if(SocailId){
                    const {data,errors} =  await socailloginUser({ 
                        variables: {
                         socailLoginProvider:socailProvider,
                         socialLoginId:SocailId,
                         email:email,
                         data:allData,
                        }
                     });
                    if (data.loginWithSocialId.status === "success") {
                            localStorage.setItem("token", data.loginWithSocialId.accessToken)
                            history("/", { replace: true });
                    }   
                    if (errors) {
                        console.log(errors);
                        setOnShow(true);
                        setErrorMessage('Failed to connect.');
                    }
            }else{
                console.log("i am here");
                setOnShow(true);
                setErrorMessage('Failed to connect.');
            }
                
            }
    }


    const onUserLogin = (event) => {
        event.preventDefault();
        if (email !== '' && password !== '') {
            loginUserr();
        }
    };

    const tokenData = verifyToken();
    const [VerifyToken] = useLazyQuery(tokenData, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
    });


    useEffect(() => {
        async function verify() {
            const res = await VerifyToken();
            if (res?.data) {
                if (res?.data?.verifyToken?.status === "success") {
                    history("/findmeal_one", { replace: true });
                }
                else {
                    setOnShow(true);
                    localStorage.removeItem("token")
                    setErrorMessage(res?.data?.verifyToken?.message)
                }
            }
            if (res?.error) {
                setOnShow(true);
                localStorage.removeItem("token")
                setErrorMessage(res?.error)
            }
        }
        if (data) {
            if (data.loginHost.status === "success") {

                if (data.loginHost.user.role.name == "host") {
                    localStorage.setItem("token", data.loginHost.accessToken)
                    verify();
                }
                if (data.loginHost.user.role.name == "diner") {
                    localStorage.setItem("token", data.loginHost.accessToken)
                    verify();
                }
            }

        }
        if (error) {
            setOnShow(true);
            if (error.message === 'Failed to fetch') {
                setErrorMessage('Failed to fetch')
            } else {
                const message = error?.message ? JSON.parse(error?.message).message : "Something went wrong";
                console.log(message);
                setErrorMessage(message);
            }
        }

    }, [data, error]);

    return (
        <div>
            <Container className="userLogin" fluid>
                <Row className="dFlex justify-content-center align-items-center vh-100">
                    <Col md={11} className="row justify-content-center midContainer">
                        <Col md={5}>
                            <Col as="div" className="logo_login">
                                <Link to="/" className="loginLogo mb-3 d-inline-block"><img src="./loginLogo.png" alt='footer logo' /></Link>
                                <Col className="headingLogin">
                                    <Col as="div" className="heading mb-5">
                                        <h2 className="m-0 small text-black bold">Enjoy a cozy hot meal at your neighbour's crib.</h2>
                                        <p className="parra semibold mt-4">Join us and be rewarded whenever you eat with us. A <br class="d-none d-lg-inline" />good taste deserves more treats.</p>
                                    </Col>
                                    <Col as="div" className="appStoreIcons pt-1">
                                        <h2>Download App</h2>
                                        <div className="d-inline-block aapblock">
                                            <img src="./googlePlay.png" className="img-fluid" alt='googlePay Icon' />
                                            <img src="./appStore.png" className="img-fluid" alt='appStore Icon' />
                                        </div>
                                    </Col>
                                </Col>
                                {/* <br /><br />
                                <Link to="/" className="gotoHome text-decoration-none d-inline-block rounded-pill mt-2">
                                    <HiHome color="var(--bs-white)" className="me-2" /> Back To Home
                                </Link> */}
                            </Col>
                        </Col>
                        <Col md={6} className="pe-0">
                            <Row className="d-md-flex h-100">
                                <Col className="rightLog position-relative">
                                    <section id="scalOut" className="backImage position-absolute scalImg">
                                        <img src="./imageBunch.png" className="img-fluid" alt='bgImage' />
                                    </section>
                                    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
                                        <div className="front">
                                            <div className='heading mb-4'>
                                                <h2 className='small lh-1 fs-normal'>Hi, Welcome Back</h2>
                                            </div>
                                            {errorMessage === 'Failed to fetch' ? <span style={{ color: 'red' }}>{errorMessage + ', Network error'}</span> : ''}
                                            <Form onSubmit={onUserLogin} className="loginform">

                                                <FloatingLabel controlId="floatingInputGrid" label="Email address" className="mb-3 pb-1">
                                                    <Form.Control type="text" placeholder="Email or Contact Number"
                                                        onChange={(e) => {
                                                            if (!e.target.value) {
                                                                setErrorMessage({
                                                                    error: 'invalid email',
                                                                    message: 'Please Enter Email or Phone number'
                                                                })
                                                            } else {
                                                                setErrorMessage(undefined)
                                                            }
                                                            setEmail(e.target.value);
                                                        }} />

                                                    {errorMessage && errorMessage.error === 'email' && (
                                                        <div className="invalid-feedback d-block">
                                                            {errorMessage.message}
                                                        </div>
                                                    )}
                                                    {errorMessage && errorMessage.error === 'invalid email' && (
                                                        <div className="invalid-feedback d-block">
                                                            {errorMessage.message}
                                                        </div>
                                                    )}
                                                </FloatingLabel>

                                                <FloatingLabel controlId="floatingInputGrid" label="Password" className="mb-3 pb-1">
                                                    <Form.Control type={passwordType ? 'password' : 'text'} placeholder="Password" onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }} />
                                                    <span className="showPass" onClick={() => {
                                                        setPasswordType(!passwordType)
                                                    }}>  {passwordType ? <FiEye size="20" color="var(--bs-border)" /> : <FiEyeOff size="20" color="var(--bs-border)" />}</span>



                                                    {errorMessage && errorMessage.error === 'password' && (
                                                        <div className="invalid-feedback d-block">
                                                            {errorMessage.message}
                                                        </div>
                                                    )}
                                                </FloatingLabel>

                                                {errorMessage && errorMessage.error === 'role' && (
                                                    <div className="invalid-feedback d-block">
                                                        {errorMessage.message}
                                                    </div>
                                                )}

                                                <Form.Group className="mb-4 mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                                    <div className="form-check d-inline-block">
                                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault5" />
                                                        <label className="form-check-label fw-bold pt-1 ps-1" for="flexRadioDefault5">Remember me</label>
                                                    </div>
                                                    <Form.Label className='float-end'><Link to="" className="forgotPass text-decoration-none">Forgot Password?</Link></Form.Label>
                                                </Form.Group>

                                                <Button className="w-100 py-2 lh-lg border-0 mb-1" variant="primary" type="submit">Login</Button>
                                            </Form>
                                            <Col md={12} className="orText position-relative">
                                                <span className="d-block text-center text-uppercase"><i>or</i></span>
                                            </Col>
                                            <Col md={12} className="d-flex justify-content-between socialButton">
                                                <FacebookLogin 
                                                    className="facebookplus fs-bold"
                                                    appId={process.env.REACT_APP_SOCAIL_LOGIN_FACEBOOK_APP_ID}
                                                    // autoLoad="{true}"
                                                    fields='name,email,picture'
                                                    // onClick={responseFacebook}
                                                    callback={responseFacebook}
                                                    />
                                                <GoogleLogin 
                                                    className="googleplus fw-bold"
                                                    // clientId="1234567890-abc123def456.apps.googleusercontent.com"
                                                    clientId={process.env.REACT_APP_SOCAIL_LOGIN_GOOGLE_CLIENT_ID}
                                                    onSuccess={responseGoogle}
                                                    onFailure={responseGoogle}
                                                    // fetchBasicProfile={true}
                                                    cookiePolicy={'single_host_origin'}
                                                    />
                                            </Col>
                                            <Col md={12} className="signUpLink">
                                                <p className="text-center mt-4">Don’t have an account? <Button className="text-decoration-none" onClick={addClass} >Sign up</Button></p>
                                            </Col>
                                        </div>
                                        <div className="back">
                                            <div className='heading mb-4'><h2 className='small lh-1 fs-normal'>Hi, Welcome Back</h2></div>
                                            <Form className="loginform">
                                                <Form.Group controlId="formBasicEmail" label="Email or Contact Number" className="mb-3 pb-1 d-flex justify-content-between">
                                                    <Col as="div" className="w-25 bg-white flagBox">
                                                        <ReactFlagsSelect
                                                            selected={selected}
                                                            placeholder="sds"
                                                            fullWidth={5}
                                                            onSelect={(code) => setSelected(code)} />
                                                    </Col>
                                                    <FloatingLabel controlId="floatingInputGrid" label="Mail ID" className="w70">
                                                        <Form.Control type="email" placeholder="9123 4567" />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <FloatingLabel controlId="floatingInputGrid" label="Password" className="mb-2 pb-1">
                                                    <Form.Control type={passwordType ? 'password' : 'text'} placeholder="Password" />
                                                    <span className="showPass" onClick={() => {
                                                        setPasswordType(!passwordType)
                                                    }}>  {passwordType ? <FiEye size="20" color="var(--bs-border)" /> : <FiEyeOff size="20" color="var(--bs-border)" />}</span>
                                                </FloatingLabel>

                                                <Form.Group className="mb-4 mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                                    <div className="form-check d-inline-block">
                                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="back01" />
                                                        <label className="form-check-label fw-bold pt-1 ps-1" for="back01">Remember me</label>
                                                    </div>
                                                    <Form.Label className='float-end'><Link to="" className="forgotPass text-decoration-none">Forgot Password?</Link></Form.Label>
                                                </Form.Group>

                                                <Button className="w-100 py-2 lh-lg border-0 mb-1" variant="primary" type="submit">Login</Button>
                                            </Form>
                                            <Col md={12} className="orText position-relative">
                                                <span className="d-block text-center text-uppercase"><i>or</i></span>
                                            </Col>
                                            <Col md={12} className="d-flex justify-content-between socialButton">
                                                <FacebookLogin 
                                                    className="facebookplus fs-bold"
                                                    appId={process.env.REACT_APP_SOCAIL_LOGIN_FACEBOOK_APP_ID}
                                                    // autoLoad="{true}"
                                                    fields='name,email,picture'
                                                    // onClick={responseFacebook}
                                                    callback={responseFacebook}
                                                    />
                                               
                                                <GoogleLogin 
                                                className=""
                                                clientId={`${process.env.REACT_APP_SOCAIL_LOGIN_GOOGLE_CLIENT_ID}`}
                                                //     onSuccess={responseGoogle}
                                                // onFailure={responseGoogle}
                                                onRequest={responseGoogle}
                                                // fetchBasicProfile={true}
                                                // cookiePolicy={'single_host_origin'}
                                                />
                                            </Col>
                                            <Col md={12} className="signUpLink">
                                                <p className="text-center mt-4">Don’t have an account? <Link to="/loginthree" className="text-decoration-none ps-1 text-danger">Sign up</Link></p>
                                            </Col>
                                        </div>
                                    </section>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Container>
            {onShow == true ? (
                <Modal dialogClassName="modalSM" centered show={onShow} onHide={() => setOnShow(false)} >
                    <Modal.Body className="d-flex flex-column justify-content-center align-items-center border08 text-center py-5 bgtheme">

                        <svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                        </svg>

                        <h1 className="text-white fs32 semibold">Error !</h1>
                        <p className="fs24 regular lh-sm mb-5 text-white">
                            {errorMessage}
                        </p>
                    </Modal.Body>
                </Modal>
            ) : null}
        </div>
    )
}
export default LoginPage