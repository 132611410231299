import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { client } from '../..';
import { verifyToken } from '../../queries/queries';

const useAuth = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return true
  } else {
    return false
  }
}

const HostAppLayout = () => {
  const [dataSet, setDataSet] = useState({});
  const [isHost, setisHost] = useState(true);
  const getUserData = async () => {
    const tokenData = verifyToken();
    const { data, error } = await client.query({
      query: tokenData
    });
    if (data) {
      if (data.verifyToken.status === "success") {
        setDataSet(data.verifyToken.user);
        setisHost(data.verifyToken.user.last_login === "host");
      }
      else {
        localStorage.removeItem("token");
        setDataSet(null);
      }
    }
    console.log("error", error);
    if (error != undefined) {
      localStorage.removeItem("token");
      setDataSet(null);
    }
  }
  useEffect(() => {
    getUserData();
  }, []);

  const auth = useAuth();
  return auth && dataSet && isHost ? <Outlet context={{ user: dataSet }} /> : <Navigate to="/loginpage" />
};

export default HostAppLayout;
