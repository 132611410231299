import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { client } from '../..';
import { verifyToken } from '../../queries/queries';


const useAuth = () => {
  const token = localStorage.getItem('token')
  if (token) {
    return true
  } else {
    return false
  }
}

const DinerAppLayout = () => {
  const [dataSet, setDataSet] = useState({});
  const [isDiner, setisDiner] = useState(true);
  const [isData, setisData] = useState(true);
  const getUserData = async () => {
    const tokenData = verifyToken();
    const { data, error } = await client.query({
      query: tokenData
    });
    if (data) {
      if (data.verifyToken.status === "success") {
        setDataSet(data.verifyToken.user);
        setisDiner(data.verifyToken.user.last_login === "diner");
        setisData(data.verifyToken.user !== null)
      }
      else {
        localStorage.removeItem("token");
        setDataSet(null);
      }
    }
    if (error != undefined) {
      console.log("error", error)
      localStorage.removeItem("token");
      setDataSet(null);
    }
  }
  useEffect(() => {
    getUserData();
  }, []);
  console.log(isData);
  const auth = useAuth();
  return auth && isData && isDiner ? <Outlet context={{ user: dataSet }} /> : <Navigate to="/loginpage" />
};

export default DinerAppLayout;
