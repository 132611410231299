import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FiMapPin } from "react-icons/fi";
import { BiCalendar } from "react-icons/bi";
import { Col } from 'react-bootstrap';

const MobileHeader = () => {
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };
    return (
        <div>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <header className="p-4 shadow-none mobileheader">
                    <section className="tobbleButton d-inline-block me-2">
                        <div className={`app ${isActive ? "left" : "right"}`}>
                            <span className="leftButton" onClick={handleToggle}>Pickup</span>
                            <span className="rightButton" onClick={handleToggle}>Dine in</span>
                        </div>
                    </section>
                    <section className="calenderButton me-2">
                        <Link to="/" className="btn text-decoration-none btnCommon text-white rounded-pill"><BiCalendar size="20" /> <span className="Hidexs">Now</span></Link>
                    </section>
                    <section className="locationButton me-2">
                        <Link to="/" className="btn text-decoration-none btngray text-black rounded-pill">
                            <FiMapPin size="18" color="var(--theme-color)" /> <span className="Hidexs">10 Bayfront Ave</span>
                        </Link>
                    </section>
                </header>
            </div>
            <Col as="span" className="d-none Allfilter" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">Book Table</Col>
        </div>
    )
}

export default MobileHeader;