import React, { useState } from "react";
import { FaMapMarkedAlt, FaMinus, FaPlus } from "react-icons/fa";

const Quantity = ({ max }) => {
  const [qty, setQty] = useState(0);

  const decreaseQty = () => {
    if (qty <= 0) {
      setQty(0);
    } else {
      setQty(qty - 1);
    }
  };

  const increaseQty = () => {
    if (qty >= max) {
      setQty(max);
    } else {
      setQty(qty + 1);
    }
  };

  const onChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= 0 && value <= max) {
      setQty(value);
    }
  };
  return (
    <div>
      <button onClick={decreaseQty}> <FaMinus size="10" className="minusIcon" color="var(--bs-grayDark)" /> </button>
      <input type="text" onChange={onChange} value={qty} />
      <button onClick={increaseQty}> <FaPlus size="10" className="plusIcon" color="var(--bs-grayDark)" /> </button>
    </div>
  );
};

export default Quantity;
