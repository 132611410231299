import { verifyToken } from "./queries/queries";

export const addClass = () => {
  document.getElementById("signUpFlip").classList.add("scalIn");
  setTimeout(function () {
    document.getElementById("scalOut").classList.add("scalOut");
  }, 100);

  setTimeout(function () {
    document.getElementById("signUpFlip").classList.add("flip");
  }, 1000);

  setTimeout(function () {
    document.getElementById("scalOut").classList.remove("scalOut");
  }, 1000);

  setTimeout(function () {
    document.getElementById("signUpFlip").classList.remove("scalIn");
  }, 1000);
}

export const arrayFilterWithSameData = (primary_array, secondary_array) => {
  if (primary_array) {
    let [selected_array] = primary_array?.map((data) =>
      secondary_array && (secondary_array?.filter((item) => (item.value === data)))
    )
    return selected_array
  }
}

export const arrayFilterWithoutSameData = (primary_array, secondary_array) => {
  if (primary_array) {
    let [unselected_array] = primary_array?.map((data) => secondary_array && (secondary_array?.filter((item) => (item.value !== data))))
    return unselected_array
  }
}