import React, {useState } from 'react'
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { FiChevronDown, FiMapPin, FiMenu } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import { BiCalendar, BiSearch, BiUpArrow } from "react-icons/bi";
import { Form, Button, Dropdown, Image } from 'react-bootstrap';
import { client } from '..';
import HostHeader from './hostHeader';
import { HiOutlineLogout, HiOutlineMail, HiOutlineShoppingCart, HiOutlineUser } from 'react-icons/hi';


const AfterLoginHeader = () => {
  const navigate = useNavigate();
  const user = useOutletContext()


  const [isActive, setActive] = useState("false");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const handleToggle = () => {
    setActive(!isActive);
  };

  const logout = () => {
    localStorage.removeItem("token")
    setToken(localStorage.getItem("token"));
    client.cache.reset()
    navigate('/loginpage')
  }
  const gotoProfile = () => {
    navigate('/diner/myprofile');
  }

  return (
    <div>
      <header className="headerone d-flex align-items-center justify-content-between fixed-top">
        {user?.user?.last_login == "diner" ? <>
          <section className="toggleIcon ms-4 me-4 pe-2">
            <FiMenu size="45" color="var(--theme-color)" />
          </section>
          <section className="siteLogo me-2">
            <Link to="/" className="mt-3 mb-md-0 logo">
              <img src="/logo.png" alt='logo' />
            </Link>
          </section>
          <section className="tobbleButton d-inline-block me-2">
            <div className={`app ${isActive ? "left" : "right"}`}>
              <span className="leftButton" onClick={handleToggle}>Pickup</span>
              <span className="rightButton" onClick={handleToggle}>Dine in</span>
            </div>
          </section>
          <section className="calenderButton me-2">
            <Link to="" className="btn text-decoration-none btnCommon text-white rounded-pill"><BiCalendar size="20" /> <span className="Hide-xs">Now</span></Link>
          </section>
          <section className="locationButton me-2">
            <Link to="" className="btn text-decoration-none btngray text-black rounded-pill">
              <FiMapPin size="18" color="var(--theme-color)" /> <span className="Hide-xs">10 Bayfront Ave</span>
            </Link>
          </section>
          <section className="searchSection me-4">
            <Form className="position-relative">
              <Form.Group className="mbSearch" controlId="formBasicEmail">
                <Form.Control type="search" placeholder="Looking for Something?" />
              </Form.Group>
              <Button className="border-0 p-0 bg-transparent position-absolute" variant="primary" type="submit">
                {/* <BiSearch size="26" color="var(--bs-border)" /> */}
                <GoSearch size="21" color="var(--bs-border)" />
              </Button>
            </Form>
          </section>
          <section className="cartIconBlack position-relative me-4">
            <img src="/shoppingCartBlack.svg" className="img-fluid" alt="shoppingCart Icon" />
            <span>5</span>
          </section>
          {/* user account */}
          <section className="userAvtar">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <Image
                  className="border border-danger"
                  src="/avtarImage.png"
                  roundedCircle
                  width="46"
                />
                <div className="userName">
                  Welcome
                  <span>
                    {user?.user && user?.user?.first_name} <FiChevronDown size="16" />{" "}
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow headerbadge">
                <BiUpArrow size="20" color="var(--bs-white)" className="uparrow" />
                <Dropdown.Item className="px-2" onClick={gotoProfile} ><HiOutlineUser size="22" color="var(--theme-color)" className="me-2" /> Profile</Dropdown.Item>
                <Dropdown.Item className="px-2" href="#/action-2"><HiOutlineShoppingCart size="22" color="var(--theme-color)" className="me-2" /> My Order</Dropdown.Item>
                <Dropdown.Item className="px-2" href="#/action-3"><HiOutlineMail size="22" color="var(--theme-color)" className="me-2" /> Inbox</Dropdown.Item>
                <Dropdown.Item className="px-2" onClick={logout} ><HiOutlineLogout size="22" color="var(--theme-color)" className="me-2" /> Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </section></>
          : <HostHeader user={user?.user} logout={logout} />}
      </header>
    </div>
  )
}

export default AfterLoginHeader;