import React, { useState } from 'react'
import { Col, Form, Button } from 'react-bootstrap';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { addClass } from '../../commanFunctions';

const DinerSignupSeven = params => {
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [pass, setPass] = useState(undefined);
    const [confirmPass, setConfirmPass] = useState(undefined);
    const [passOneHide, setPassOneHide] = useState(true);
    const [passTwoHide, setPassTwoHide] = useState(true);

    const onNextPage = (event) => {
        event.preventDefault();
        if (pass == '') {
            setErrorMessage({
                error: 'empty password',
                message: 'Please Enter Password'
            })
        }
        else if (confirmPass == '') {
            setErrorMessage({
                error: 'empty conf-password',
                message: 'Please Enter Confirm Password'
            })
        }
        else if (pass !== confirmPass) {
            setErrorMessage({
                error: 'not matched',
                message: 'Password and Confirm Password not matched'
            })
        }
        else {
            addClass()
            setTimeout(() => {
                params.setPassword(pass)
                params.setStepNo(8)
            }, 1400)
        }
    };


    return (

        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front">
                <div className="heading">
                    <h2 className='small lh-1 fs-normal mb-2'>Signup</h2>
                    <p className="semibold m-0">Please create your new password.</p>
                </div>
                <Col as="section" className="stepsBar pt-4">
                    <Col as="ul" className="d-flex justify-content-between list-unstyled">
                        <li className="p2 active first"><span>1</span></li>
                        <li className="p2 active"><span>2</span></li>
                        <li className="p2 active"><span>3</span></li>
                        <li className="p2 active"><span>4</span></li>
                        <li className="p2 last"><span>5</span></li>
                    </Col>
                </Col>
                <Form className="pt-3">
                    <Form.Group controlId="formPassword" label="Password" className="mb-3 position-relative">
                        <Form.Control type={passOneHide ? 'password' : 'text'} placeholder="Password"
                            autoComplete="on"
                            onChange={(e) => {
                                if (!e.target.value) {
                                    setErrorMessage({
                                        error: 'empty password',
                                        message: 'Please Enter Password'
                                    })
                                } else {
                                    setErrorMessage(undefined)
                                }
                                setPass(e.target.value);
                            }} />
                        <span className="showPass" onClick={() => { setPassOneHide(!passOneHide) }}>
                            {passOneHide ? <FiEye size="20" color="var(--bs-border)" /> : <FiEyeOff size="20" color="var(--bs-border)" />}
                        </span>
                        {errorMessage && errorMessage.error === 'empty password' && (
                            <div className="invalid-feedback d-block">
                                {errorMessage.message}
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword" label="Re-enter Password" className="mb-4 pb-3 position-relative">
                        <Form.Control type={passTwoHide ? 'password' : 'text'} placeholder="Re-enter Password"
                            autoComplete="on"
                            onChange={(e) => {
                                if (!e.target.value) {
                                    setErrorMessage({
                                        error: 'empty conf-password',
                                        message: 'Please Enter Confirm Password'
                                    })
                                } else {
                                    setErrorMessage(undefined)
                                }
                                setConfirmPass(e.target.value);
                            }} />
                        <span className="showPass" onClick={() => { setPassTwoHide(!passTwoHide) }}>
                            {passTwoHide ? <FiEye size="20" color="var(--bs-border)" /> : <FiEyeOff size="20" color="var(--bs-border)" />}
                        </span>
                        {errorMessage && (errorMessage.error === 'empty conf-password' || errorMessage.error === 'not matched') && (
                            <div className="invalid-feedback d-block">
                                {errorMessage.message}
                            </div>
                        )}
                    </Form.Group>
                    <Button onClick={onNextPage} className="w-100 py-2 lh-lg border-0 hvr-float-shadow" variant="primary">Next</Button>
                </Form>
            </div>
        </section>
    )
}
export default DinerSignupSeven