import React, { useEffect, useState } from 'react'
import HostPersonal02 from "./hostpersonal02";
import HostPersonal03A from "./hostpersonal03a";
import HostPersonal03B from "./hostpersonal03b";
import HostPersonal04 from "./hostpersonal04";
import HostPersonal05 from "./hostpersonal05";
import HostPersonal06 from "./hostpersonal06";
import HostPersonal07 from "./hostpersonal07";
import HostPersonal08 from "./hostpersonal08";
import HostPersonal09 from "./hostpersonal09";
import HostPersonal10 from "./hostpersonal10";
import HostPersonal11 from "./hostpersonal11";
import HostPersonal12 from "./hostpersonal12";


const HostPersonal01 = (params) => {
    const [stepNo, setStepNo] = useState(params.stepNo || 2);
    const [createAcc, setCreateAcc] = useState(false);


    // -------------------API data variables------------------------
    const [registrationType, setRegistrationType] = useState("");
    const [nricFile, setNricFile] = useState({ front: null, back: null });
    const [acradoc,] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [companyStatus, setCompanyStatus] = useState("");
    const [companyAmendment, setCompanyAmendment] = useState(true);
    // const [registrationNo,] = useState("");
    // const [dateInc,] = useState("");
    // const [primaryBusiness,] = useState("");
    // const [secBusiness,] = useState("");
    // const [shareCapital,] = useState("");
    // const [currency,] = useState("");
    // const [representative,] = useState("");
    // const [repCitizenship,] = useState("");
    // const [designation,] = useState("");
    const [fullname, setFullname] = useState("");
    const [nricNumber, setNricNumber] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [citizenship, setCitizenship] = useState("");
    const [address, setAddress] = useState("");
    const [profileAmendment, setProfileAmendment] = useState(true);

    const [propertyUser, setPropertyUser] = useState("");
    const [outlets,] = useState([]);
    const [outletData, setOutletData] = useState([]);
    // const [postalCode, setPostalCode] = useState(0);
    // const [floorNumber, setFloorNumber] = useState(0);
    // const [unitNumber, setUnitNumber] = useState(0);
    // const [propertyFile, setPropertyFile] = useState({});
    const [referralcode, setReferralcode] = useState("");

    // -------------------Component base state------------------------
    const [pushAddress, setPushAddress] = useState(false);
    const [startDate, setStartDate] = useState(null);
    // const [dInc,] = useState(null);

    // -------------------Date & Age calculate-------------------------
    const handleDate = (date) => {
        setStartDate(date);
        let currYear = new Date().getFullYear();
        let dd = new Date(date).getDate();
        let mm = new Date(date).getMonth() + 1;
        let yyyy = new Date(date).getFullYear();
        setAge(`${currYear - yyyy}`);
        setDateOfBirth(yyyy + "-" + mm + "-" + dd);
    };

    useEffect(() => {
        if (pushAddress === true) {
            outlets.push(outletData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pushAddress]);

    // -------------Back to my profile link----------------
    const goBack = () => {
        params.setStepNo(1);
        params.setSelectedSide('')
    };
    return (
        <>
            {stepNo === 1 && goBack()}
            {stepNo === 2 && (
                <HostPersonal02
                    setStepNo={setStepNo}
                    setRegistrationType={setRegistrationType}
                />)
            }
            {stepNo === 3 && registrationType === "nric" ? (
                <HostPersonal03A
                    setStepNo={setStepNo}
                    nricFile={nricFile}
                    setNricFile={setNricFile}
                />
            ) : stepNo === 3 && registrationType === "singpass" ? (
                <HostPersonal03B setStepNo={setStepNo} />
            ) : null}
            {stepNo === 4 && (
                <HostPersonal04
                    setStepNo={setStepNo}
                    registrationType={registrationType}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    companyStatus={companyStatus}
                    setCompanyStatus={setCompanyStatus}
                    companyAmendment={companyAmendment}
                    setCompanyAmendment={setCompanyAmendment}
                />
            )}
            {stepNo === 5 && (
                <HostPersonal05
                    setStepNo={setStepNo}
                    registrationType={registrationType}
                    handleDate={handleDate}
                    startDate={startDate}
                    fullname={fullname}
                    setFullname={setFullname}
                    nricNumber={nricNumber}
                    setNricNumber={setNricNumber}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    age={age}
                    setAge={setAge}
                    profileAmendment={profileAmendment}
                    setProfileAmendment={setProfileAmendment}
                />
            )}
            {stepNo === 6 && (
                <HostPersonal06
                    setStepNo={setStepNo}
                    registrationType={registrationType}
                    gender={gender}
                    setGender={setGender}
                    citizenship={citizenship}
                    setCitizenship={setCitizenship}
                    address={address}
                    setAddress={setAddress}
                    profileAmendment={profileAmendment}
                    setProfileAmendment={setProfileAmendment}
                />
            )}
            {stepNo === 7 && (
                <HostPersonal07
                    setStepNo={setStepNo}
                    address={address}
                    setAddress={setAddress}
                    outletData={outletData}
                    setOutletData={setOutletData}
                />
            )}
            {stepNo === 8 && (
                <HostPersonal08
                    setStepNo={setStepNo}
                    setPropertyUser={setPropertyUser}
                />
            )}
            {stepNo === 9 && (
                <HostPersonal09
                    setStepNo={setStepNo}
                    propertyUser={propertyUser}
                    outletData={outletData}
                    setPushAddress={setPushAddress}
                />
            )}
            {stepNo === 10 && (
                <HostPersonal10 setStepNo={setStepNo} outletData={outletData} />
            )}
            {stepNo === 11 && (
                <HostPersonal11
                    setStepNo={setStepNo}
                    outletData={outletData}
                    referralcode={referralcode}
                    setReferralcode={setReferralcode}
                />
            )}
            {stepNo === 12 && (
                <HostPersonal12
                    setStepNo={setStepNo}
                    userRole={params.UserRole}
                    userHostType={params.userHostType}
                    registrationType={registrationType}
                    nricfront={nricFile.front}
                    nricback={nricFile.back}
                    acradoc={acradoc}
                    company_name={companyName}
                    company_status={companyStatus}
                    companyAmendment={companyAmendment}
                    fullname={fullname}
                    nricNumber={nricNumber}
                    dob={dateOfBirth}
                    age={age}
                    gender={gender}
                    citizenship={citizenship}
                    address={address}
                    profileAmendment={profileAmendment}
                    outlets={outletData}
                    createAcc={createAcc}
                    setCreateAcc={setCreateAcc}
                    repNricNO={""}
                    registration_no={''}
                    date_inc={''}
                    primary_business={''}
                    sec_business={''}
                    companyAddress={"null"}
                    share_capital={''}
                    currency={''}
                    representative={''}
                    rep_citizenship={''}
                    designation={''}
                />
            )}
        </>
    )
}

export default HostPersonal01