import React, { useState } from 'react'
import { Col, Modal } from 'react-bootstrap';
import { HiCheckCircle } from 'react-icons/hi';
import { RiArrowRightSLine } from "react-icons/ri";


const DinerSignupTen = (params) => {
    const [selectedSide, setSelectedSide] = useState("");
    const [onShow, setOnShow] = useState(false)

    const onSelect = async (data) => {
        if (data === "email") {
            setSelectedSide("LEFT")
        } else if (data === "mobile") {
            setSelectedSide("RIGHT")
        } else {
            setSelectedSide('')
        }
        setOnShow(true)
    }
    return (
        <>
            <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
                <div className="front">
                    <div className="heading">
                        <h2 className='small lh-1 fs-normal mb-2'>Account Setup <span className="skipCont float-end">Skip <RiArrowRightSLine size="30" /></span></h2>
                        <p className="semibold m-0 lh-1">Welcome aboard! Your account has been successfully created. Just a few more things to help you get going.</p>
                    </div>
                    <br /><br />
                    <p className="semibold mb-2 pb-3 lh-1">Preferred 2FA Method</p>
                    <Col as="section" className="signpUser mb-5 row text-center">
                        <Col className="dineUser" onClick={() => onSelect("email")}>
                            <div className={`iconleft cursor-pointer ${selectedSide === "LEFT" ? 'bgtheme' : ''}`}>
                                <svg width="82" height="64" viewBox="0 0 82 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.83205 13.4189C2.14276 12.9594 1.21145 13.1457 0.751925 13.8349C0.292396 14.5242 0.478657 15.4555 1.16795 15.9151L2.83205 13.4189ZM36.1926 37.4621L37.0247 36.214L37.0247 36.214L36.1926 37.4621ZM45.8074 37.4621L44.9753 36.214L44.9753 36.214L45.8074 37.4621ZM80.8321 15.9151C81.5213 15.4555 81.7076 14.5242 81.2481 13.8349C80.7886 13.1457 79.8572 12.9594 79.168 13.4189L80.8321 15.9151ZM1.16795 15.9151L35.3606 38.7101L37.0247 36.214L2.83205 13.4189L1.16795 15.9151ZM35.3605 38.7101C38.7755 40.9869 43.2245 40.9869 46.6395 38.7101L44.9753 36.214C42.568 37.819 39.432 37.819 37.0247 36.214L35.3605 38.7101ZM46.6395 38.7101L80.8321 15.9151L79.168 13.4189L44.9753 36.214L46.6395 38.7101ZM10.6667 63.8337H71.3333V60.8337H10.6667V63.8337ZM71.3333 63.8337C76.9484 63.8337 81.5 59.282 81.5 53.667H78.5C78.5 57.6252 75.2915 60.8337 71.3333 60.8337V63.8337ZM81.5 53.667V10.3337H78.5V53.667H81.5ZM81.5 10.3337C81.5 4.71878 76.9484 0.166992 71.3333 0.166992V3.16699C75.2915 3.16699 78.5 6.3756 78.5 10.3337H81.5ZM71.3333 0.166992H10.6667V3.16699H71.3333V0.166992ZM10.6667 0.166992C5.05177 0.166992 0.5 4.71876 0.5 10.3337H3.5C3.5 6.37562 6.70862 3.16699 10.6667 3.16699V0.166992ZM0.5 10.3337V53.667H3.5V10.3337H0.5ZM0.5 53.667C0.5 59.282 5.05178 63.8337 10.6667 63.8337V60.8337C6.70861 60.8337 3.5 57.6252 3.5 53.667H0.5Z" fill="#404040" />
                                </svg>                                                  <h4>Email</h4>
                            </div>
                        </Col>
                        <Col className="hostUser" onClick={() => onSelect("mobile")}>
                            <div className={`iconright cursor-pointer ${selectedSide === "RIGHT" ? 'bgtheme' : null}`}>
                                <svg width="52" height="89" viewBox="0 0 52 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M44.3965 0H7.6049C3.66131 0 0.451172 3.21014 0.451172 7.15373V81.2478C0.451172 85.1914 3.66131 88.4016 7.6049 88.4016H44.3965C48.3401 88.4016 51.5502 85.1914 51.5502 81.2478V7.15373C51.5502 3.21014 48.3401 0 44.3965 0ZM7.6049 4.08759H44.3965C46.0867 4.08759 47.4626 5.46346 47.4626 7.15373V12.1525H4.53912V7.15373C4.53912 5.46349 5.91499 4.08759 7.60527 4.08759H7.6049ZM47.4624 16.2401V65.9179H4.53894V16.2401H47.4624ZM44.3963 84.3136H7.60472C5.91448 84.3136 4.53857 82.9378 4.53857 81.2475V70.006H47.4621V81.2482C47.4628 82.9377 46.0869 84.3136 44.3966 84.3136H44.3963Z" fill="#404040" />
                                </svg>                                                  <h4>Mobile</h4>
                            </div>
                        </Col>
                    </Col>
                    <p className="themeColor mt-4 lh-sm fs14 regular">
                        Enhance your Cribbly account security by choosing one of the 2FA method. You can change your preferences from your profile settings at a later time.
                    </p>
                </div>
            </section>
            {onShow == true ? (
                <Modal
                    backdropClassName="action01"
                    dialogClassName="modalSM"
                    contentClassName="action03"
                    centered
                    show={onShow}
                    onHide={() => setOnShow(false)}>
                    <Modal.Body className="d-flex flex-column justify-content-center align-items-center border08 text-center py-5 bgtheme">
                        <HiCheckCircle size="120" className='mt-5 mb-3' color="var(--bs-white)" />
                        <h1 className="text-white fs32 regular">Success!</h1>
                        <p className="fs24 regular lh-sm mb-5 text-white">
                            Your account has been successfully created, Please login to
                            Proceed.
                        </p>
                    </Modal.Body>
                </Modal>
            ) : null}
        </>
    )
}
export default DinerSignupTen