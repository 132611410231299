import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from "./components/home"
import Quantity from "./pages/findmymeal/quantity"
import FindMyMeal from "./pages/findmymeal/findmeal_one"
import OutletProfile from "./pages/findmymeal/outletprofile"
import LoginPage from "./pages/auth/loginpage"
import LoginThree from "./pages/auth/loginthree"
import ForgotPassThree from "./pages/auth/forgotpassthree";
import ForgotPassTwo from "./pages/auth/forgotpasstwo"
import ForgotPassOne from "./pages/auth/forgotpassone";
import NewPassword from "./pages/auth/newpassword";

import DinerSignup from "./pages/diner/dinersignup";
import DinerSignupOne from "./pages/diner/dinersignup01";
import DinerSignupTwo from "./pages/diner/dinersignup02";
import DinerSignupThree from "./pages/diner/dinersignup03";
import DinerSignupFour from "./pages/diner/dinersignup04";
import DinerSignupFive from "./pages/diner/dinersignup05";
import DinerSignupSix from "./pages/diner/dinersignup06";
import DinerSignupSeven from "./pages/diner/dinersignup07";
import DinerSignupEight from "./pages/diner/dinersignup08";
import DinerSignupNine from "./pages/diner/dinersignup09";
import DinerSignupTen from "./pages/diner/dinersignup10";

import HostSignup from "./pages/host/hostsignup"
import DinerMyProfile from "./pages/diner/myprofile"
import HostMyProfile from "./pages/host/hostmyprofile"
import { authentication } from "./commanFunctions";
import { verifyToken } from "./queries/queries";
import { client } from ".";
import HostAppLayout from "./pages/auth/HostAppLayout";
import DinerAppLayout from "./pages/auth/DinerAppLayout";
import CommonLayout from "./pages/auth/commonLayout";
import GuestLayout from "./pages/auth/guestLayout";
import AuthLayout from "./pages/auth/Authlayout";
import HostStepOne from "./pages/host/hoststepone";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<GuestLayout />}>
            <Route path="/" exact element={<Home />} />
            <Route path="/outletprofile" exact element={<OutletProfile />} />
            <Route path="/findmeal_one" exact element={<FindMyMeal />} />
          </Route>
          <Route element={<DinerAppLayout />}>
            <Route path="/diner" exact element={<FindMyMeal />} />
            <Route path="/diner/myprofile" exact element={<DinerMyProfile />} />
          </Route>

          <Route element={<HostAppLayout />}>
            <Route path="/host" exact element={<HostStepOne />} />
            <Route path="/host/myprofile" exact element={<HostMyProfile />} />
          </Route>
          <Route path="/quantity" exact element={<Quantity />} />
          <Route path="/loginthree" exact element={<LoginThree />} />
          <Route path="/dinersignup01" exact element={<DinerSignupOne />} />
          <Route path="/dinersignup02" exact element={<DinerSignupTwo />} />
          <Route path="/dinersignup03" exact element={<DinerSignupThree />} />
          <Route path="/dinersignup04" exact element={<DinerSignupFour />} />
          <Route path="/dinersignup05" exact element={<DinerSignupFive />} />
          <Route path="/dinersignup06" exact element={<DinerSignupSix />} />
          <Route path="/dinersignup07" exact element={<DinerSignupSeven />} />
          <Route path="/dinersignup08" exact element={<DinerSignupEight />} />
          <Route path="/dinersignup09" exact element={<DinerSignupNine />} />
          <Route path="/dinersignup10" exact element={<DinerSignupTen />} />

          <Route element={<AuthLayout />}>
            <Route path="/hostsignup" exact element={<HostSignup />} />
            <Route path="/loginpage" exact element={<LoginPage />} />
            <Route path="/dinersignup" exact element={<DinerSignup />} />
            <Route path="/forgotpassone" exact element={<ForgotPassOne />} />
            <Route path="/forgotpasstwo" exact element={<ForgotPassTwo />} />
            <Route path="/forgotpassthree" exact element={<ForgotPassThree />} />
            <Route path="/newpassword" exact element={<NewPassword />} />
          </Route>



        </Routes>
      </BrowserRouter>
    </div>
  );
}

// async function RequireAuth() {
//   const tokenData = verifyToken();

//   const userData = client.readQuery({
//     query: tokenData
//   });
//   console.log("ghjh",userData);
//   // let location = useLocation();

//   // if (!auth.user) {
//   //   // Redirect them to the /login page, but save the current location they were
//   //   // trying to go to when they were redirected. This allows us to send them
//   //   // along to that page after they login, which is a nicer user experience
//   //   // than dropping them off on the home page.
//   //   return <Navigate to="/login" state={{ from: location }} />;
//   // }

//   return <Outlet />;
// }

export default App;
