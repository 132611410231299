import React, { useState } from "react";
import { Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";
import { BsCalendar2 } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { addClass } from "../../../commanFunctions";

const HostPersonal05 = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);

  const handleChangeRaw = (value) => {
    // if (value === "tomorrow") {
    //   setStartDate(addDays(new Date(), 1));
    // }
  };

  const onNextpage = (event) => {
    event.preventDefault();
    if (params.fullname === "") {
      setErrorMessage({
        error: "empty full name",
        message: "Please Enter Full Name",
      });
    } else if (params.nricNumber === "") {
      setErrorMessage({
        error: "empty nric number",
        message: "Please Enter NRIC Number",
      });
    } else if (params.dateOfBirth === "") {
      setErrorMessage({
        error: "invalid date",
        message: "Invalid Date",
      });
    } else if (params.age <= "17") {
      setErrorMessage({
        error: "less age",
        message: "Age more then 18+",
      });
    } else {
      addClass()
      setTimeout(() => {
        params.setStepNo(6);
      }, 1400)
    }
  };
  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(4);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">Please choose your method of registration type.</p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/2.6)" }}>5</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-2">
          <Form onSubmit={onNextpage} className="accForm">

            <FloatingLabel controlId="floatingInput" label="Full Name" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Full Name"
                value={params.fullname}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty full name",
                      message: "Please Enter Full Name",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setFullname(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error === "empty full name" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>

            <FloatingLabel controlId="floatingInput" label="NRIC Number" className="mb-3">
              <Form.Control
                type="text"
                placeholder="NRIC Number"
                value={params.nricNumber}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessage({
                      error: "empty nric number",
                      message: "Please Enter NRIC Number",
                    });
                  } else {
                    setErrorMessage(undefined);
                  }
                  params.setNricNumber(e.target.value);
                }}
              />
              {errorMessage &&
                errorMessage.error === "empty nric number" && (
                  <div className="invalid-feedback d-block">
                    {errorMessage.message}
                  </div>
                )}
            </FloatingLabel>


            <Row className="mb-2 pb-3">
              <Col>
                <FloatingLabel controlId="floatingInput" label="Date of Birth" className="datePickerBox">
                  <Form.Control type="text" />
                  <DatePicker
                    selected={params.startDate}
                    className="form-control bg-white grayBdb"
                    placeholderText="Date of Birth"
                    value={params.startDate}
                    onChange={(date) => params.handleDate(date)}
                    onChangeRaw={(event) => {
                      if (event.target.value === "") {
                        setErrorMessage({
                          error: "invalid date",
                          message: "Invalid Date",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      handleChangeRaw(event.target.value);
                    }}
                  />
                  <BsCalendar2
                    className="calendarIcon mt-1"
                    size="20"
                    color="var(--theme-color)"
                  />
                  {errorMessage &&
                    errorMessage.error === "invalid date" && (
                      <div className="invalid-feedback d-block">
                        {errorMessage.message}
                      </div>
                    )}
                </FloatingLabel>

              </Col>
              <Col>

                <FloatingLabel controlId="floatingInput" label="Age">
                  <Form.Control
                    type="text"
                    placeholder="Age"
                    value={params.age}
                    disabled
                  />
                  {errorMessage && errorMessage.error === "less age" && (
                    <div className="invalid-feedback d-block">
                      {errorMessage.message}
                    </div>
                  )}
                </FloatingLabel>
              </Col>
            </Row>

            {params.registrationType === "nric" ? (
              <>
                <div className="mb-2 lh-1 form-radio">
                  <input
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    checked={params.profileAmendment === true}
                    onChange={(e) => params.setProfileAmendment(true)}
                    defaultChecked
                  />
                  <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-1`}>
                    Information is correct. No amendment required
                  </label>
                </div>

                <div className="lh-1 form-radio">
                  <input
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    checked={params.profileAmendment === false}
                    onChange={(e) => params.setProfileAmendment(false)}
                  />
                  <label className="ms-3 pt-1 lh-sm semibold themeColor" htmlFor={`inline-radio-2`}>
                    Information is incorrect. Proceed to amend
                  </label>
                </div>
              </>
            ) : null}

            <Row className="pt-4 mt-2">
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  variant="link"
                  type="button"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostPersonal05