import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { HiOutlineUpload } from "react-icons/hi";
import { addClass } from "../../../commanFunctions";

const HostBusiness07 = (params) => {
  const [errorMessage, setErrorMessage] = useState(undefined);

  const onNextpage = (event) => {
    event.preventDefault();
    if (params.nricFile?.front?.name === undefined) {
      setErrorMessage({
        error: "empty nric-front",
        message: "Please Upload NRIC Front",
      });
    } else if (params.nricFile?.back?.name === undefined) {
      setErrorMessage({
        error: "empty nric-back",
        message: "Please Upload NRIC Back",
      });
    } else {
      addClass();
      setTimeout(() => {
        params.setStepNo(8);
      }, 1400);
    }
  };

  const goBack = () => {
    addClass();
    setTimeout(() => {
      params.setStepNo(6);
    }, 1400);
  };

  return (
    <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
      <div className="front">
        <div className="heading">
          <h2 className="small lh-1 fs-normal mb-2">Become A Host</h2>
          <p className="semibold m-0">
            Please upload your NRIC.
          </p>
        </div>
        <Col as="section" className="stepsBar2 pt-4">
          <Col as="ul" className="list-unstyled">
            <Col as="li" className="p2 active first"><span className="fs14" style={{ marginLeft: "calc(100%/2.1)" }}>7</span></Col>
          </Col>
        </Col>
        <Col as="section" className="signpUser pt-2">
          <Form onSubmit={onNextpage}>
            <Row className="flex-wrap">
              <Col lg="12" className="pb0">
                <Form.Group controlId="nric-front" className="mb-3">
                  <Form.Label className="nricFile fs20 semibold themeColor w-100 mb-0 form-label">
                    {params.nricFile?.front?.name || "NRIC Front"}{" "}
                    <span role="button" className="px-3 pt-2 pb-3 lh-1 shadowMD border border08 d-inline-block bg-white float-end">
                      <HiOutlineUpload size="24" color="var(--theme-color)" />
                    </span>
                  </Form.Label>
                  <Form.Control
                    name="nricFront"
                    type="file"
                    className="d-none"
                    accept=".png, .jpg, .pdf"
                    onChange={(event) => {
                      if (event.target.files[0].name === undefined) {
                        setErrorMessage({
                          error: "empty nric-front",
                          message: "Please Upload NRIC Front",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      params.setNricFile({
                        ...params.nricFile,
                        front: event.target.files[0],
                      });
                    }}
                  />
                  {errorMessage &&
                    errorMessage.error === "empty nric-front" && (
                      <div
                        className="invalid-feedback d-block"
                        style={{ textAlign: "start" }}
                      >
                        {errorMessage.message}
                      </div>
                    )}
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group controlId="nric-back" className="mb-4">
                  <Form.Label className="nricFile fs20 semibold themeColor w-100 form-label">
                    {params.nricFile?.back?.name || "NRIC Back"}
                    <span role="button" className="px-3 pt-2 pb-3 lh-1 shadowMD border border08 d-inline-block bg-white float-end">
                      <HiOutlineUpload size="24" color="var(--theme-color)" />
                    </span>
                  </Form.Label>
                  <Form.Control
                    name="nricBack"
                    type="file"
                    className="d-none"
                    accept=".jpg, .png, .pdf"
                    onChange={(event) => {
                      if (event.target.files[0].name === undefined) {
                        setErrorMessage({
                          error: "empty nric-back",
                          message: "Please Upload NRIC Back",
                        });
                      } else {
                        setErrorMessage(undefined);
                      }
                      params.setNricFile({
                        ...params.nricFile,
                        back: event.target.files[0],
                      });
                    }}
                  />
                  {errorMessage &&
                    errorMessage.error === "empty nric-back" && (
                      <div
                        className="invalid-feedback d-block"
                        style={{ textAlign: "start" }}
                      >
                        {errorMessage.message}
                      </div>
                    )}
                </Form.Group>
              </Col>
            </Row>

            <p className="fs16 textGreen semibold mb-0">
              *Acceptable files .jpg, .png, <br className="d-none d-lg-inline" />.pdf. File size not exceeding 1MB.
            </p>
            <Row className="mt-4">
              <Col className="align-self-center">
                <Button
                  className="fs26 themeColor semibold text-decoration-none lh-1 p-0"
                  type="button"
                  variant="link"
                  onClick={goBack}
                >
                  <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  Previous
                </Button>
              </Col>
              <Col>
                <Button
                  className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    </section>
  )
}

export default HostBusiness07