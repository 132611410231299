import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { HiHome } from "react-icons/hi";
import { BsClock } from "react-icons/bs";
import { Link } from 'react-router-dom';
import './loginpage.css';


const addClass = () => {
    document.getElementById("signUpFlip").classList.add("scalIn");
    setTimeout(function() {
        document.getElementById("scalOut").classList.add("scalOut");    
    }, 100);
    
    setTimeout(function() {
        document.getElementById("signUpFlip").classList.add("flip");
    }, 1000);
    
    setTimeout(function() {
        document.getElementById("scalOut").classList.remove("scalOut");    
    }, 1000);

    setTimeout(function() {
        document.getElementById("signUpFlip").classList.remove("scalIn");
    }, 1000);
}

const ForgotPassOne = () => {
    return (
        <div>
            <Container className="userLogin" fluid>
                <Row className="dFlex justify-content-center">
                    <Col md={11} className="row justify-content-center midContainer">
                        <Col md={5}>
                            <Col as="div" className="logo_login my-5 py-md-5">
                                <Link to="/" className="loginLogo mb-5 d-block"><img src="./loginLogo.png" alt='footer logo' /></Link>                            
                                <Col className="headingLogin">
                                    <Col as="div" className="heading mb-5">
                                        <h2 className="m-0 small text-black">
                                            Enjoy a cozy hot meal at your neighbour's crib.
                                        </h2>
                                        <p className="parra mt-4">
                                            Join us and be rewarded whenever you eat with us. 
                                            A good taste deserves more treats.
                                        </p>
                                    </Col>
                                    <Col as="div" className="appStoreIcons pt-1">
                                        <h2>Download App</h2>
                                        <div className="d-inline-block aapblock">
                                            <img src="./googlePlay.png" className="img-fluid" alt='googlePay Icon' />
                                            <img src="./appStore.png" className="img-fluid" alt='appStore Icon' />
                                        </div>
                                    </Col>                                    
                                </Col>
                                <br /><br />
                                <Link to="/" className="gotoHome text-decoration-none d-inline-block rounded-pill mt-5">
                                    <HiHome color="var(--bs-white)" className="me-2" /> Back To Home
                                </Link>                                
                            </Col>
                        </Col>
                        <Col md={6} className="pe-0">
                            <Row className="d-md-flex h-100">
                                <Col className="rightLog position-relative mt-5">
                                <br /><br />
                                    <section id="scalOut" className="backImage position-absolute scalImg ">
                                        <img src="./imageBunch.png" className="img-fluid" alt='bg image' />
                                    </section>
                                    <section id="signUpFlip" className="mobilePur m-auto cardBox">
                                        <div className="front">
                                            <div className='heading mb-2 pb-2'>
                                                <h2 className='small lh-1 fs-normal mb-0'>Forgot Password</h2>
                                                <p className="semibold">Please enter your registered Email Address or Contact Number.</p>
                                            </div>
                                            <Form>
                                                <Form.Group controlId="formBasicName" className="mb-4 pb-3" Label="Email Address or Contact Number">
                                                    <Form.Control type="email" placeholder="Email Address or Contact Number" />
                                                </Form.Group>                                                
                                                <Button className="w-100 py-2 lh-lg border-0" variant="primary" type="submit">Reset Password</Button>
                                            </Form>                                            
                                        </div>                                        
                                    </section>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Container> 
        </div>        
    )
}
export default ForgotPassOne