import React, { useState } from 'react'
import { Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap';
import { BsCalendar2 } from "react-icons/bs";
import { RiArrowRightSLine } from "react-icons/ri";
import DatePicker from 'react-datepicker';
import { addClass } from '../../commanFunctions';

const DinerSignupTwo = params => {
    const [errorMessage, setErrorMessage] = useState(undefined);

    const handleChangeRaw = (value) => {
        // if (value === "tomorrow") {
        //   setStartDate(addDays(new Date(), 1));
        // }
    };


    const onNextPage = async (event) => {
        event.preventDefault();
        if (params.dateOfBirth === '') {
            setErrorMessage({ error: 'invalid date', message: 'Invalid Date' })
        } else if (params.gender === '') {
            setErrorMessage({ error: "empty gender", message: "Please Enter Gender" })
        } else {
            addClass()
            setTimeout(() => {
                params.setStepNo(3)
            }, 1400)
        }
    };
    const onSkip = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(3)
        }, 1400)
    }
    const goBack = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(1)
        }, 1400)
    }

    return (
        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front overflow-visible">
                <div className="heading">
                    <h2 className='small lh-1 fs-normal mb-2'>Signup <span className="skipCont float-end" onClick={onSkip}>Skip <RiArrowRightSLine size="30" /> </span></h2>
                    <p className="semibold m-0">Please input your Date of Birth and Gender.</p>
                </div>
                <Col as="section" className="stepsBar pt-4">
                    <Col as="ul" className="d-flex justify-content-between list-unstyled">
                        <li className="p2 active first"><span>1</span></li>
                        <li className="p2 active"><span>2</span></li>
                        <li className="p2"><span>3</span></li>
                        <li className="p2"><span>4</span></li>
                        <li className="p2 last"><span>5</span></li>
                    </Col>
                </Col>
                <Col as="section" className="signpUser pt-3">
                    <Form className="accForm">
                        <Form.Group className="mb-3 position-relative datePickerBox" controlId="formDateOfBirth">
                            <Form.Control type="text" placeholder="" />
                            <DatePicker
                                className="semibold bg-white"
                                placeholderText='Date of Birth (DD/MM/YYYY)'
                                selected={params.startDate}
                                value={params.startDate}
                                onChange={(date) => params.handleDate(date)}
                                onChangeRaw={(event) => {
                                    if (event.target.value == '') {
                                        setErrorMessage({
                                            error: 'invalid date',
                                            message: 'Invalid Date'
                                        })
                                    } else {
                                        setErrorMessage(undefined)
                                    }
                                    handleChangeRaw(event.target.value)
                                }
                                }
                            />
                            <BsCalendar2 className="calendarIcon" size="24" color="var(--bs-grayDark)" />
                            {errorMessage && errorMessage.error === 'invalid date' && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>

                        <Form.Select
                            className="semibold selectControl"
                            aria-label="Floating label select example"
                            value={params.gender}
                            onChange={(e) => {
                                if (!e.target.value) {
                                    setErrorMessage({
                                        error: "empty gender",
                                        message: "Please Enter Gender",
                                    });
                                } else {
                                    setErrorMessage(undefined);
                                }
                                params.setGender(e.target.value);
                            }}>
                            <option value="not selected">Please Select Gender</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                            <option value="OTHER">Other</option>
                            <option value="Do Not Disclose">Do Not Disclose</option>
                        </Form.Select>

                        {errorMessage && errorMessage.error === "empty gender" && (
                            <div className="invalid-feedback d-block">
                                {errorMessage.message}
                            </div>
                        )}

                        <Row className="mt-5">
                            <Col className="align-self-center">
                                <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button" onClick={goBack}>
                                    <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Previous
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                                    variant="primary"
                                    type="button"
                                    onClick={onNextPage}>
                                    Next
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </div>
        </section>
    )
}
export default DinerSignupTwo