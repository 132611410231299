import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Badge,
    Form,
    FloatingLabel,
} from "react-bootstrap";
import { FiEye, FiChevronDown } from "react-icons/fi";
import { HiOutlineCamera } from "react-icons/hi";
import { BsCalendar2, BsPatchCheckFill } from "react-icons/bs";
import { BiUpArrow } from "react-icons/bi";
import { RiCloseCircleFill, RiAddCircleFill } from "react-icons/ri";
import { TbCirclePlus } from "react-icons/tb";
import { AiFillCloseCircle } from "react-icons/ai";
import "react-pro-sidebar/dist/css/styles.css";

import "./../account.css";
import Footer from "../../components/footer";
import ProfileMenu from "../../components/profilemenu";
import { GetUserById } from "../../queries/queries";
import { useQuery } from "@apollo/client";
import DatePicker from "react-datepicker";
import { allergiesOptions, dietaryPrefrences } from "../../utils/staticData";
import { arrayFilterWithoutSameData, arrayFilterWithSameData } from "../../commanFunctions";
import AfterLoginHeader from "../../components/afterLoginHeader";
import { useOutletContext } from "react-router";

const HostMyProfile = () => {
    const user = useOutletContext();

    // -----------------Initial-Data---------------------------
    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        user_profile: {
            gender: "",
            dob: "",
            account_verified: false,
            profile_image: "",
            allergies: [],
            dietary_prefrence: [],
        },
        phone_number: "",
        email: "",
        auth_method: "",
        auth_login: false,
        auth_account_changes: false,
        auth_order: false,
        emergency_contact: [{ first_name: "", last_name: "", },],
    });

    // ----------------------Profile Image---------------------------------------
    const [profileImage, setProfileImage] = useState('')
    const [Dob, setDob] = useState(new Date())

    // --------------------Dietary Prefrences & Allergies (static data)-------------------------
    const [dietaryItems, setDietaryItems] = useState(dietaryPrefrences || []);
    const [allergyItems, setAllergyItems] = useState(allergiesOptions || []);

    // -------------------Dietary Prefrences & Allergies (selected data)------------------------
    const [selectedDietaries, setSelectedDietaries] = useState([]);
    const [selectedAllergies, setSelectedAllergies] = useState([]);

    //-----------------------Emergency Contact Form (dynamic)-----------------------------------
    const [formValues, setFormValues] = useState([{ first_name: "", last_name: "" }]);

    // -----------------------------GET USER PROFILE DATA---------------------------------------
    const userId = user?.user?.id;
    const getUserData = GetUserById();
    const { loading, data } = useQuery(getUserData, {
        variables: { userId: Number(userId) },
    });

    // -----------------------------useEffect & Render Method-------------------------------
    useEffect(() => {
        if (data?.getUserById?.user) {
            let newdata = (data?.getUserById?.user);
            setUserData(newdata);
            setDob(newdata.user_profile.dob ? new Date(newdata.user_profile.dob) : new Date());
            setProfileImage(process.env.REACT_APP_IMAGE_URL + newdata?.user_profile?.profile_image)
        }
    }, [data]);

    useEffect(() => {
        let userProfile = userData?.user_profile;
        if (userProfile?.dietary_prefrence && (userProfile?.dietary_prefrence).length === undefined) {
            let dietary_array = Object.values(userProfile?.dietary_prefrence);
            let selected_dietaries = arrayFilterWithSameData(dietary_array, dietaryPrefrences);
            let unselected_dietaries = arrayFilterWithoutSameData(dietary_array, dietaryItems);

            selected_dietaries && setSelectedDietaries(selected_dietaries);
            unselected_dietaries && setDietaryItems(unselected_dietaries);
        }

        if (userProfile?.allergies && userProfile?.allergies.length === undefined) {

            let allergy_array = Object.values(userProfile?.allergies);
            let selected_allergy = arrayFilterWithSameData(allergy_array, allergiesOptions);
            let unselected_allergy = arrayFilterWithoutSameData(allergy_array, allergyItems);
            selected_allergy && setSelectedAllergies(selected_allergy);
            unselected_allergy && setAllergyItems(unselected_allergy);


        }
        const EmergencyContactDetails = userData?.emergency_contact?.map(({ __typename, ...rest }) => {
            return rest;
        });

        setFormValues(EmergencyContactDetails);
    }, [userData?.user_profile?.dietary_prefrence, userData?.user_profile?.allergies, userData?.emergency_contact]);

    // --------------------------Functions and Methods-----------------------------------------------

    const handleProfileImage = (e) => {
        let ImageUrl = URL.createObjectURL(e.target.files[0]);
        let ImageName = (e.target.files[0].name);
        setUserData({ ...userData, user_profile: { ...userData.user_profile, profile_image: ImageName } })
        setProfileImage(ImageUrl)
    }

    const handleInputChange = (e) => {
        let type = e.target.type;
        let key = e.target.id;
        if (type === "checkbox") {
            setUserData({ ...userData, [key]: e.target.checked });
        } else {
            setUserData({ ...userData, [key]: e.target.value });
        }
    };

    const handleUserProfile = (e) => {
        let key = e.target.id;
        let value = e.target.value;
        setUserData({
            ...userData,
            user_profile: { ...userData.user_profile, [key]: value },
        });
    };

    const handleMultiSelect = (name, item, index) => {
        let selectedDiets = [...selectedDietaries];
        let unselectedDiets = [...dietaryItems];
        let selectedallergy = [...selectedAllergies];
        let unselectedallergy = [...allergyItems];

        let newSelectedDiets = [];
        let newselectedAllergy = [];


        if (name === "selectedDietaries") {
            //  add data in unselected dietery state
            setDietaryItems([...dietaryItems, item]);
            // remove data from selected dietery state
            selectedDiets.splice(index, 1);
            // push data in new array 
            selectedDiets.map((data) => newSelectedDiets.push(data.value))
            // set selected data 
            setSelectedDietaries(selectedDiets);
            // set user data for api
            setUserData({ ...userData, "user_profile": { ...userData.user_profile, "dietary_prefrence": newSelectedDiets } })


        } else if (name === "dietaryItems") {
            setSelectedDietaries([...selectedDietaries, item]);
            unselectedDiets.splice(index, 1);
            [...selectedDietaries, item].map((data) => newSelectedDiets.push(data.value))
            setDietaryItems(unselectedDiets)
            setUserData({ ...userData, "user_profile": { ...userData.user_profile, "dietary_prefrence": newSelectedDiets } })

        } else if (name === "selectedAllergies") {
            setAllergyItems([...allergyItems, item]);
            selectedallergy.splice(index, 1);
            selectedallergy.map(data => newselectedAllergy.push(data.value))
            setSelectedAllergies(selectedallergy);
            setUserData({ ...userData, "user_profile": { ...userData.user_profile, "allergies": newselectedAllergy } })


        } else if (name === "allergyItems") {
            setSelectedAllergies([...selectedAllergies, item]);
            unselectedallergy.splice(index, 1);
            [...selectedAllergies, item].map(data => newselectedAllergy.push(data.value));
            setAllergyItems(unselectedallergy);
            setUserData({ ...userData, "user_profile": { ...userData.user_profile, "allergies": newselectedAllergy } });
        }

    }

    const handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    };

    const addFormFields = () => {
        setUserData({ ...userData, emergency_contact: [...userData.emergency_contact, { first_name: "", last_name: "" }] })
    };

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("save button click", userData)
    };

    return (
        <div id="bodyWrap" className="">
            <Container className="mealOne" fluid>
                <Row>
                    <Col className="px-0"><AfterLoginHeader /></Col>
                    <Col
                        as="span"
                        className="d-none proMenu"
                        data-bs-toggle="offcanvas"
                        href="#myprofile01"
                        role="button"
                        aria-controls="myprofile01"
                    >   Profile Menu{" "}
                        <FiChevronDown className="float-end" size="28" color="var(--bs-white)" />
                    </Col>
                </Row>
                <Row className="dblock">
                    <Col
                        lg="3"
                        className="ps-0 profilemenu outline-none"
                        tabIndex="-1"
                        id="myprofile01"
                        aria-labelledby="myprofile01Label"
                    >
                        <ProfileMenu />
                    </Col>
                    <Col lg="9" as="div" className="userAcc pt-4 pe-xl-5">
                        <h2 className="h2_large ms-md-4">My Profile</h2>
                        <Col as="section" className="shadowSM mt-5 ms-md-4 me-xl-5 pdAround" >
                            <Badge bg="white" className="d-block p-0">
                                <span className="rounded-pill border border-3 border-danger p-1 d-inline-block position-relative">
                                    <img
                                        src={loading ? "Loading..." : profileImage ? profileImage : "/assets/frame02_old.png"
                                        }
                                        width={150}
                                        height={150}
                                        className="rounded-pill border border-2 border-danger"
                                        alt="navimg"
                                    />
                                    <span className="position-absolute cameraIcon bgLight d-inline-block rounded-pill d-flex align-items-center justify-content-center">
                                        <HiOutlineCamera size="28" color="var(--theme-color)" />
                                    </span>
                                    <Form.Group controlId="formFile" className="cameraChooseFile">
                                        <Form.Control type="file" onChange={handleProfileImage} />
                                    </Form.Group>
                                </span>
                                <h5 className="semibold fs26 GrayComn mt-3 mb-1">
                                    {loading ? "Loading..." : userData?.first_name || userData?.last_name
                                        ? `${userData?.first_name} ${userData?.last_name}` : ""
                                    }
                                    <BsPatchCheckFill className="checkfill" size="22" color="var(--bs-greenDark)" />
                                </h5>
                                <p className="semibold fs14 gray82">
                                    <FiEye size="19" color="var(--theme-color)" /> Total Reviews 2048
                                </p>
                            </Badge>
                            <Form className="mt-4 pt-5 accForm" onSubmit={handleSubmit}>
                                <Row className="pb-4">
                                    <Col className="fs20 semibold GrayComn">Personal Details</Col>
                                    <Col className="fs16 semibold themeColor text-end">Verify my Identity</Col>
                                </Row>
                                <Row className="g_flutter gx-4">
                                    <Col xs="12" md="6">
                                        <Form.Group className="mb-4 pb-1 position-relative" controlId="first_name">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="John"
                                                value={
                                                    loading ? "Loading..." : userData?.first_name
                                                        ? userData?.first_name : ""
                                                }
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Group
                                            className="mb-4 pb-1 position-relative"
                                            controlId="last_name"
                                        >
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Doe"
                                                value={
                                                    loading ? "Loading..." : userData?.last_name
                                                        ? userData?.last_name : ""
                                                }
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FloatingLabel controlId="gender" label="Gender" className="mt-4 mb-4 pb-1 position-relative" >
                                            <Form.Select
                                                className="semibold"
                                                aria-label="Floating label select example"
                                                value={
                                                    loading ? "Loading..." : userData?.user_profile?.gender
                                                        ? userData?.user_profile?.gender : "not selected"
                                                }
                                                onChange={handleUserProfile}
                                            >
                                                <option value="not selected">Please Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                                <option value="do not disclose">Do Not Disclose</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FloatingLabel controlId="dob" label="Date of Birth" className="mt-4 mb-4 datePickerBox" >
                                            <Form.Control type="text" />
                                            <DatePicker
                                                selected={Dob}
                                                className="form-control bg-white grayBdb"
                                                placeholderText="Date of Birth"
                                                value={Dob}
                                                onChange={(date) =>
                                                    setUserData({ ...userData, user_profile: { ...userData.user_profile, dob: date, }, })
                                                }
                                                onChangeRaw={(event) => {
                                                    // if (event.target.value === "") {
                                                    //     setErrorMessage({
                                                    //         error: "invalid date",
                                                    //         message: "Invalid Date",
                                                    //     });
                                                    // } else {
                                                    //     setErrorMessage(undefined);
                                                    // }
                                                    // handleChangeRaw(event.target.value);
                                                    console.log("date-event", event);
                                                }}
                                            />
                                            <BsCalendar2 className="calendarIcon mt-1" size="20" color="var(--theme-color)" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Group className="mb-4 pb-1 position-relative" controlId="phone_number" >
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                type={loading ? "text" : "number"}
                                                placeholder="+65 9215 2547"
                                                value={
                                                    loading ? "Loading..." : userData?.phone_number
                                                        ? userData?.phone_number : ""
                                                }
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Group className="mb-4 pb-1 position-relative" controlId="email" >
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                className="bgLight"
                                                type="email"
                                                placeholder="johndoe123@hotmail.com"
                                                value={
                                                    loading ? "Loading..." : userData?.email
                                                        ? userData?.email : ""
                                                }
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="12" xl="6">
                                        <h5 className="fs20 semibold GrayComn mb-4 mt-3">
                                            Account Security
                                            <span className="fs12 semibold d-block">
                                                Change Password
                                            </span>
                                        </h5>
                                        <Form.Group
                                            className="mb-4 pb-1 position-relative"
                                            controlId="form.ControlInput7"
                                        >
                                            <Form.Label>Current Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                autoComplete={"Current Password"}
                                                placeholder="**********"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-4 pb-1 position-relative"
                                            controlId="form.ControlInput8"
                                        >
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                autoComplete={"New Password"}
                                                placeholder="**********"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-4 pb-1 position-relative"
                                            controlId="form.ControlInput9"
                                        >
                                            <Form.Label>Re-enter Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                autoComplete={"Re-enter Password"}
                                                placeholder="**********"
                                            />
                                        </Form.Group>
                                        <Button className="w-100 border-0 mt-2" type="submit">
                                            <span className="regular">Update Password</span>
                                        </Button>
                                    </Col>
                                    <Col xs="12" md="12" xl="6">
                                        <h5 className="fs20 semibold GrayComn mb-4 mt-3">
                                            &nbsp;
                                            <span className="fs12 semibold d-block">
                                                Set Account 2FA Method
                                            </span>
                                        </h5>
                                        <Col as="section" className={`signpUser row text-center`}>
                                            <Col sm="12" md="6" className="dineUser" onClick={() => setUserData({ ...userData, auth_method: "email" })}>
                                                <div className={`iconleft cursor-pointer ${userData?.auth_method === "email" ? "bgtheme" : null}`}>
                                                    <svg width="82" height="64" viewBox="0 0 82 64" fill="none" >
                                                        <path
                                                            d="M2.83205 13.4189C2.14276 12.9594 1.21145 13.1457 0.751925 13.8349C0.292396 14.5242 0.478657 15.4555 1.16795 15.9151L2.83205 13.4189ZM36.1926 37.4621L37.0247 36.214L37.0247 36.214L36.1926 37.4621ZM45.8074 37.4621L44.9753 36.214L44.9753 36.214L45.8074 37.4621ZM80.8321 15.9151C81.5213 15.4555 81.7076 14.5242 81.2481 13.8349C80.7886 13.1457 79.8572 12.9594 79.168 13.4189L80.8321 15.9151ZM1.16795 15.9151L35.3606 38.7101L37.0247 36.214L2.83205 13.4189L1.16795 15.9151ZM35.3605 38.7101C38.7755 40.9869 43.2245 40.9869 46.6395 38.7101L44.9753 36.214C42.568 37.819 39.432 37.819 37.0247 36.214L35.3605 38.7101ZM46.6395 38.7101L80.8321 15.9151L79.168 13.4189L44.9753 36.214L46.6395 38.7101ZM10.6667 63.8337H71.3333V60.8337H10.6667V63.8337ZM71.3333 63.8337C76.9484 63.8337 81.5 59.282 81.5 53.667H78.5C78.5 57.6252 75.2915 60.8337 71.3333 60.8337V63.8337ZM81.5 53.667V10.3337H78.5V53.667H81.5ZM81.5 10.3337C81.5 4.71878 76.9484 0.166992 71.3333 0.166992V3.16699C75.2915 3.16699 78.5 6.3756 78.5 10.3337H81.5ZM71.3333 0.166992H10.6667V3.16699H71.3333V0.166992ZM10.6667 0.166992C5.05177 0.166992 0.5 4.71876 0.5 10.3337H3.5C3.5 6.37562 6.70862 3.16699 10.6667 3.16699V0.166992ZM0.5 10.3337V53.667H3.5V10.3337H0.5ZM0.5 53.667C0.5 59.282 5.05178 63.8337 10.6667 63.8337V60.8337C6.70861 60.8337 3.5 57.6252 3.5 53.667H0.5Z"
                                                            fill="var(--bs-grayDark)"
                                                        />
                                                    </svg>
                                                    <h4 className="mt-4 pb-5 lh-base GrayComn">Email</h4>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6" className="hostUser" onClick={() => setUserData({ ...userData, auth_method: "mobile" })}>
                                                <div className={`iconright cursor-pointer ${userData?.auth_method === "mobile" ? "bgtheme" : null}`}>
                                                    <svg width="52" height="89" viewBox="0 0 52 89" fill="none" >
                                                        <path
                                                            d="M44.3965 0H7.6049C3.66131 0 0.451172 3.21014 0.451172 7.15373V81.2478C0.451172 85.1914 3.66131 88.4016 7.6049 88.4016H44.3965C48.3401 88.4016 51.5502 85.1914 51.5502 81.2478V7.15373C51.5502 3.21014 48.3401 0 44.3965 0ZM7.6049 4.08759H44.3965C46.0867 4.08759 47.4626 5.46346 47.4626 7.15373V12.1525H4.53912V7.15373C4.53912 5.46349 5.91499 4.08759 7.60527 4.08759H7.6049ZM47.4624 16.2401V65.9179H4.53894V16.2401H47.4624ZM44.3963 84.3136H7.60472C5.91448 84.3136 4.53857 82.9378 4.53857 81.2475V70.006H47.4621V81.2482C47.4628 82.9377 46.0869 84.3136 44.3966 84.3136H44.3963Z"
                                                            fill="var(--bs-grayDark)"
                                                        />
                                                    </svg>
                                                    <h4 className="mt-4 pb-5 lh-base GrayComn">Mobile</h4>
                                                </div>
                                            </Col>
                                        </Col>
                                        {/* checkbox */}
                                        <Col as="section" className="row profilecheckbox mt-2">
                                            <Col className="pt-1">
                                                <div className="form-check position-relative mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                        id="auth_login"
                                                        checked={userData?.auth_login}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label
                                                        className="form-check-label text-truncate overflow-hidden w-100"
                                                        htmlFor="auth_login"
                                                    >
                                                        <span>Authenticate when logging in.</span>
                                                    </label>
                                                </div>
                                                <div className="form-check position-relative mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                        id="auth_order"
                                                        checked={userData?.auth_order}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label
                                                        className="form-check-label text-truncate overflow-hidden w-100"
                                                        htmlFor="auth_order"
                                                    >
                                                        <span>Authenticate when placing an order.</span>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-check position-relative">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="flexRadioDefault"
                                                        id="auth_account_changes"
                                                        checked={userData?.auth_account_changes}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="auth_account_changes"
                                                    >
                                                        <span className="lh-sm d-inline-block">
                                                            Authenticate when making any account related
                                                            changes
                                                        </span>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Col>
                                    </Col>
                                    {/* popupover tooltip */}
                                    <Col as="section" md="12" className="mt-5">
                                        <h5 className="fs20 semibold GrayComn pb-2">
                                            Dietary Preference
                                            <span className="fs12 semibold d-block pt-1">
                                                Please set your default dietary preference filter. Leave
                                                blank if there are none.
                                            </span>
                                        </h5>
                                        <Row className="justify-content-between">
                                            <Col md="auto">
                                                {/* {console.log("selectedDietaries", selectedDietaries)} */}
                                                {selectedDietaries && selectedDietaries?.map((item, index) => (
                                                    <Badge
                                                        key={index}
                                                        pill
                                                        className="menuTag badgeColor fs16 pe-1 me-3 mb-1"
                                                        onClick={() => handleMultiSelect("selectedDietaries", item, index)}>
                                                        {item.label}{" "}
                                                        <RiCloseCircleFill role="button" size="20" color="var(--theme-color)" />
                                                    </Badge>
                                                ))}
                                            </Col>
                                            <Col md="auto" className="fs22 themeColor bold">
                                                <Col
                                                    as="div"
                                                    className="text-end pluMinus"
                                                    role="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseExample"
                                                    aria-expanded="false"
                                                    aria-controls="collapseExample"
                                                >
                                                    <TbCirclePlus size="24" color="var(--theme-color" className="align-text-top" />
                                                    {" "} Add
                                                </Col>
                                            </Col>


                                            <Col md="12" as="div" className="collapse" id="collapseExample" >
                                                <Col as="div" className="badgeOpen">
                                                    <BiUpArrow size="20" color="var(--bs-white)" className="uparrow" />
                                                    {/* {console.log(dietaryItems)} */}
                                                    {dietaryItems?.map((item, id) => (
                                                        <Badge key={id} pill bg="light"
                                                            className="menuTag themeColor fs16 pe-1 me-3"
                                                            onClick={() => handleMultiSelect("dietaryItems", item, id)}>
                                                            {item.label + " "}
                                                            <RiAddCircleFill role="button" size="20" color="var(--theme-color)" />
                                                        </Badge>
                                                    ))}
                                                </Col>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col as="section" md="12" className="mt-5">
                                        <h5 className="fs20 semibold GrayComn pb-2">
                                            Food Allergen
                                            <span className="fs12 semibold d-block pt-1">
                                                Please set your default major food allergen filters.
                                                Leave blank if there are none.
                                            </span>
                                        </h5>
                                        <Row className="justify-content-between">
                                            <Col md="auto">
                                                {/* {console.log("selectedAllergies", selectedAllergies)} */}
                                                {selectedAllergies &&
                                                    selectedAllergies?.map((item, index) => (
                                                        <Badge key={index} pill
                                                            className="menuTag badgeColor fs16 pe-1 me-3 mb-1"
                                                            onClick={() => handleMultiSelect("selectedAllergies", item, index)}>
                                                            {item.label}
                                                            <RiCloseCircleFill
                                                                role="button"
                                                                size="20"
                                                                color="var(--theme-color)"
                                                            />
                                                        </Badge>
                                                    ))}
                                            </Col>
                                            <Col md="auto" className="fs22 themeColor bold">
                                                <Col
                                                    as="div"
                                                    className="text-end pluMinus"
                                                    role="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseExample02"
                                                    aria-expanded="false"
                                                    aria-controls="collapseExample02"
                                                >
                                                    <TbCirclePlus
                                                        size="24"
                                                        color="var(--theme-color"
                                                        className="align-text-top"
                                                    />{" "}
                                                    Add
                                                </Col>
                                            </Col>
                                            <Col md="12" as="div" className="collapse" id="collapseExample02" >
                                                <Col as="div" className="badgeOpen">
                                                    <BiUpArrow size="20" color="var(--bs-white)" className="uparrow" />
                                                    {/* {console.log(allergyItems)} */}
                                                    {allergyItems?.map((item, id) => (
                                                        <Badge key={id} pill bg="light"
                                                            className="menuTag themeColor fs16 pe-1 me-3"
                                                            onClick={() => handleMultiSelect("allergyItems", item, id)}>
                                                            {item.label + " "}
                                                            <RiAddCircleFill role="button" size="20" color="var(--theme-color)" />
                                                        </Badge>
                                                    ))}
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col as="section" md="12" className="mt-2">
                                        <h5 className="fs20 semibold GrayComn pb-2">
                                            &nbsp;
                                            <span className="fs14 semibold d-block pt-1">
                                                *Always check on the contents / ingredients with the
                                                Host(s), prior to purchase. While we endeavour to
                                                facilitate a safe environment, <br />
                                                we shall not be held liable for any damages or injury
                                                suffered as a result of an allergic reaction.
                                            </span>
                                        </h5>
                                    </Col>
                                    <Col as="section" md="12" className="mt-4">
                                        <h5 className="fs20 semibold GrayComn pb-2">
                                            Emergency Contact
                                            <span className="fs12 semibold d-block pt-1">
                                                Please provide your emergency contact details.
                                            </span>
                                        </h5>
                                        <Col className="mt-2 pt-2 accForm">
                                            <span className="fs12 semibold d-block mb-4">
                                                Contact 1 :
                                            </span>
                                            {/* {console.log(formValues)} */}
                                            {formValues?.map((element, index) => (
                                                <div
                                                    className="form-inline position-relative"
                                                    key={index}
                                                >
                                                    <Row className="g_flutter gx-4">
                                                        <Col xs="12" md="6">
                                                            <Form.Group
                                                                className="mb-4 pb-1 position-relative"
                                                                controlId="form.Input1"
                                                            >
                                                                <Form.Label>First Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="first_name"
                                                                    placeholder="Michael"
                                                                    value={element.first_name || ""}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" md="6">
                                                            <Form.Group
                                                                className="mb-4 pb-1 position-relative"
                                                                controlId="form.Input2"
                                                            >
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="last_name"
                                                                    placeholder="Doe"
                                                                    value={element.last_name || ""}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    {index ? (
                                                        <AiFillCloseCircle
                                                            size="35"
                                                            color="var(--theme-color)"
                                                            className="removeField position-absolute"
                                                            onClick={() => removeFormFields(index)}
                                                        />
                                                    ) : null}
                                                </div>
                                            ))}
                                            <Col
                                                as="section"
                                                md="12"
                                                className="fs22 themeColor bold"
                                            >
                                                <Col
                                                    as="div"
                                                    role="button"
                                                    onClick={() => addFormFields()}
                                                >
                                                    <TbCirclePlus
                                                        size="24"
                                                        color="var(--theme-color)"
                                                        className="align-text-top"
                                                    />{" "}
                                                    Add Emergency Contact
                                                </Col>
                                            </Col>
                                            <br />
                                        </Col>
                                        <Button
                                            className="button submit col-md-5 m-auto d-block mt-5 mb-3"
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col
                            as="section"
                            className="shadowSM shadowSMacc mt-5 ms-md-4 me-md-5 text-center py-5"
                        >
                            <h5 className="fs20 semibold GrayComn mb-5">
                                Account Management
                                <span className="fs12 semibold d-block pt-2">
                                    Decide what you would like to do with your Cribbly Account.
                                </span>
                            </h5>
                            <Button
                                className="button rounded-pill fs20 susAcc bgLight GrayComn border-0 px-4 me-2"
                                variant="danger"
                                type="submit"
                            >
                                Suspend Account
                            </Button>
                            <Button
                                className="button rounded-pill fs20 cloAcc border-0 px-4"
                                variant="danger"
                                type="submit"
                            >
                                Close Account
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <br />
            <br />
            <Footer />
        </div>
    );
};
export default HostMyProfile;
