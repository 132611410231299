import { useMutation } from '@apollo/client';
import React, { createRef, useEffect, useState } from 'react'
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { addClass } from '../../commanFunctions';
import { dinerSignup } from '../../queries/queries';

const DinerSignupEight = params => {
    const history = useNavigate()
    const [termsCondition, setTermsCondition] = useState(false);
    const [marketingUpdates, setMarketingUpdates] = useState(false);
    const [onShow, setOnShow] = useState(false)
    const [createAcc, setCreateAcc] = useState(false);
    const [errorPopup, setErrorPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [errorPopupMessage, setPopupErrorMessage] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('')
    const [render, setRender] = useState(false);

    const reCaptchaRef = createRef({})

    const handleCaptchaToken = (e) => {
        if (!!e === false) {
            setErrorMessage({
                error: "unchecked captcha",
                message: "Please checked captchbox",
            })
        } else {
            setRecaptchaToken(e)
            setErrorMessage(undefined)
        }
    }

    const onCaptchaRender = () => {
        const grecaptcha = reCaptchaRef?.current?.props?.grecaptcha;
        grecaptcha?.render("gcaptcha", { sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY, callback: handleCaptchaToken })
    }

    useEffect(() => {
        setRender(true)
        if (render) {
            onCaptchaRender()
        }
        // eslint-disable-next-line
    }, [render])

    const signUp = dinerSignup();
    const [SignUpUser, { data, error }] = useMutation(signUp, { variables: { firstName: params.firstName, lastName: params.lastName, email: params.email, phoneNumber: params.phoneNumber, password: params.password, jobTitle: "", department: "", empolyeeNumber: "", userRole: Number(params.userRole) } });

    const onNextPage = async (event) => {
        if (termsCondition === false) {
            setErrorMessage({
                error: 'terms condition',
                message: 'Please Agree to terms & Conditions'
            })
        } else if (!!recaptchaToken === false) {
            setErrorMessage({
                error: "unchecked captcha",
                message: "Please checked captchbox",
            })
        } else {
            setCreateAcc(true);
        }
    };

    useEffect(() => {
        if (createAcc) {
            SignUpUser()
        }
    }, [createAcc])

    useEffect(() => {
        if (data) {
            if (data.addUser.status === 'success') {
                addClass()
                setTimeout(() => {
                    params.setStepNo(9)
                }, 1400)
            }
            else {
                setOnShow(true)
                setErrorPopup(true);
                setPopupErrorMessage(data.addUser.message)
            }
        }
        if (error) {
            if (error.message === 'Failed to fetch') {
                console.log(error.message)
            } else {
                const errMessage = JSON.parse(error.message)
                console.log(errMessage)
            }
        }
    }, [data, error]);

    const goBack = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(7)
        }, 1400)
    }

    return (
        <>
            <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
                <div className="front">
                    <div className="heading">
                        <h2 className='small lh-1 fs-normal mb-2'>Signup</h2>
                        <p className="semibold m-0 lh-1">Please read, confirm and accept the following terms to proceed.</p>
                    </div>
                    <Col as="section" className="stepsBar pt-3">
                        <Col as="ul" className="d-flex justify-content-between list-unstyled">
                            <li className="p2 active first"><span>1</span></li>
                            <li className="p2 active"><span>2</span></li>
                            <li className="p2 active"><span>3</span></li>
                            <li className="p2 active"><span>4</span></li>
                            <li className="p2 active last"><span>5</span></li>
                        </Col>
                    </Col>
                    <p className="semibold mb-3 lh-1">For Diner</p>
                    <Form className="pt3">
                        <Form.Group className="pb-3" controlId="exampleForm.ControlTextarea1">
                            <div className="form-check d-inline-block">
                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="back01"
                                    onChange={(e) => {
                                        setTermsCondition(e.target.checked)
                                        if (!e.target.checked) {
                                            setErrorMessage({
                                                error: 'terms condition',
                                                message: 'Please Agree to terms & Conditions'
                                            })
                                        }
                                        else {
                                            setErrorMessage(undefined)
                                        }
                                    }} />
                                <label className="form-check-label semibold ps-3" htmlFor="back01">
                                    <b className="text-danger">*</b>I confirm that I have read, understood and accepted
                                    Cribbly User <Link to="/" className="navlink text-danger text-decoration-none">Terms &#38; Conditions</Link> and <Link to="/" className="navlink text-danger text-decoration-none">Privacy Policy</Link>.
                                </label>
                            </div>
                            {errorMessage && errorMessage.error === 'terms condition' && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4 pb-4" controlId="exampleForm.ControlTextarea1">
                            <div className="form-check d-inline-block">
                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="back02"
                                    onChange={(e) => {
                                        setMarketingUpdates(e.target.checked)
                                    }} />
                                <label className="form-check-label semibold ps-3" htmlFor="back02">
                                    I would like to receive updates, special offers and marketing promotions from Cribbly Pte Ltd. This option may be changed in your account later.
                                </label>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-4 pb-5 text-center" controlId="exampleForm.ControlTextarea1">
                            {/* <img src="./captcha.png" alt="captcha image" /> */}
                            <ReCAPTCHA
                                id="gcaptcha"
                                className="d-inline-block"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={handleCaptchaToken}
                                ref={reCaptchaRef}
                                onExpired={() => setRecaptchaToken(null)}
                            />
                            {errorMessage && errorMessage.error === "unchecked captcha" && (
                                <div className="invalid-feedback d-block">
                                    {errorMessage.message}
                                </div>
                            )}
                        </Form.Group>

                        <Row>
                            <Col className="align-self-center">
                                <Button className="fs26 themeColor semibold text-decoration-none lh-1 p-0" variant="link" type="button" onClick={goBack}>
                                    <svg width="9" height="16" className="align-baseline me-3" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15L1 8L8 1" stroke="#C32148" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Previous
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className="w-100 py-2 lh-lg border-0 hvr-float-shadow"
                                    variant="primary"
                                    type="button"
                                    onClick={onNextPage}>
                                    Next
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </section>
            {createAcc === true && errorPopup === true ? <Modal dialogClassName="modalSM" centered show={onShow} onHide={() => setOnShow(false)} >
                <Modal.Body className="d-flex flex-column justify-content-center align-items-center border08 text-center py-5 bgtheme">

                    <svg width="94" height="86" viewBox="0 0 94 86" fill="none" className='mt-5 mb-3' xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M36.3676 6.90288C41.0317 -1.38885 52.9702 -1.38885 57.6343 6.90288L91.6741 67.4184C96.2485 75.5509 90.3717 85.5995 81.0406 85.5995H12.9609C3.63005 85.5995 -2.24687 75.5509 2.3277 67.4184L36.3676 6.90288ZM53.1001 67.3001C53.1001 70.6691 50.3692 73.4001 47.0001 73.4001C43.6314 73.4001 40.9003 70.6691 40.9003 67.3001C40.9003 63.931 43.6314 61.2001 47.0001 61.2001C50.3692 61.2001 53.1001 63.931 53.1001 67.3001ZM47.0001 18.5001C43.6314 18.5001 40.9003 21.2312 40.9003 24.6001V42.9001C40.9003 46.269 43.6314 49.0001 47.0001 49.0001C50.3692 49.0001 53.1001 46.269 53.1001 42.9001V24.6001C53.1001 21.2312 50.3692 18.5001 47.0001 18.5001Z" fill="white" />
                    </svg>

                    <h1 className="text-white fs32 semibold">Error !</h1>
                    <p className="fs24 regular lh-sm mb-5 text-white">
                        {errorPopupMessage}
                    </p>
                </Modal.Body>
            </Modal> : null}
        </>
    )
}
export default DinerSignupEight