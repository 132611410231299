import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { HiHome } from 'react-icons/hi'
import { Link } from 'react-router-dom'

const HostSignupLayout = ({ children }) => {
    return (
        <Container className="userLogin" fluid>
            <Row className="dFlex justify-content-center align-items-center vh-100">
                <Col md={11} className="row justify-content-center midContainer">
                    <Col md={5}>
                        <Col as="div" className="logo_login">
                            <Link to="/" className="loginLogo mb-3 d-block">
                                <img src="./loginLogo.png" alt="footer logo" />
                            </Link>
                            <Col className="headingLogin">
                                <Col as="div" className="heading mb-5">
                                    <h2 className="m-0 small text-black bold">Start a rewarding food entreprenuer jouney with us.</h2>
                                    <p className="parra semibold mt-4">Save on rent. Work from home. The best part? A Crib <br className="d-none d-lg-inline" />Cafe to call your own.</p>
                                </Col>
                                <Col as="div" className="appStoreIcons pt-1">
                                    <h2 className="fs52">Download App</h2>
                                    <div className="d-inline-block aapblock">
                                        <img src="./googlePlay.png" className="img-fluid" alt="googlePay Icon" />
                                        <img src="./appStore.png" className="img-fluid" alt="appStore Icon" />
                                    </div>
                                </Col>
                            </Col>
                            <br />
                            <br />
                            <Link
                                to="/"
                                className="gotoHome text-decoration-none d-inline-block rounded-pill mt-2"
                            >
                                <HiHome color="var(--bs-white)" className="me-2" /> Back To
                                Home
                            </Link>
                        </Col>
                    </Col>

                    <Col md={6} className="second-page pe-0">
                        <Row className="d-md-flex h-100">
                            <Col className="rightLog position-relative">
                                <section id="scalOut" className="backImage position-absolute scalImg">
                                    <img src="./imageBunch.png" className="img-fluid" alt="bunchimage" />
                                </section>
                                {children}
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </Container>
    )
}

export default HostSignupLayout