import React from 'react'
import { Col } from 'react-bootstrap';
import { RiArrowRightSLine } from "react-icons/ri";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { addClass } from '../../commanFunctions';

const DinerSignupNine = (params) => {

    const onNextPage = () => {
        addClass()
        setTimeout(() => {
            params.setStepNo(10)
        }, 1400)
    }

    return (
        <section id="signUpFlip" className="mobilePur m-auto cardBox d-flex align-items-center h-100">
            <div className="front">
                <div className="heading">
                    <h2 className='small lh-1 fs-normal mb-2'>Account Setup <span className="skipCont float-end">Skip <RiArrowRightSLine size="30" /></span></h2>
                    <p className="semibold m-0 lh-1">Welcome aboard! Your account has been successfully created. Just a few more things to help you get going.</p>
                </div>
                <br /><br />
                <p className="semibold mb-2 pb-3 lh-1">Alternative Login method</p>
                <Col className="d-flex justify-content-between socialButton">
                    <FacebookLoginButton className="facebookplus fs-bold ms-0" onClick={onNextPage}>
                        <span>Login with Facebook</span>
                    </FacebookLoginButton>
                    <GoogleLoginButton className="googleplus fw-bold me-0" onClick={onNextPage}>
                        <span>Login with Google</span>
                    </GoogleLoginButton>
                </Col>
                <p className="semibold text-danger mt-3 lh-sm fs14">
                    Link your Facebook or Google to your Cribbly account and you can log in using one of your preferred method instead.
                </p>
            </div>
        </section>
    )
}
export default DinerSignupNine